@import '../variables.scss';

.googleSignInButton {
  @include google-sign-in-button();
}

.outlookSignInButton {
  @include outlook-sign-in-button();
}

.navWrapper {
  width: 100%;
  z-index: 998;

  .nav {
    width: 100%;

    .isSticky {
      @include elevation-1();
      background: rgba($white, 0.9);
      backdrop-filter: blur(8px);
    }

    .inner {
      width: 100%;
      max-width: 1200px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      @media (max-width: $breakpoint-mobile) {
        padding: 15px 10px 15px 20px;
      }
    }

    .logo {
      height: 32px;
      width: 127px;
      background-image: url(../../images/logo-nav.svg);
      background-size: 127px 32px;
      flex: none;
      cursor: pointer;

      @media (max-width: $breakpoint-mobile) {
        width: 30px;
      }
    }

    .loginSignup {
      display: flex;

      .divider {
        width: 1px;
        background-color: $border-color;
        margin: 0 8px;
      }
    }

    .button {
      flex: none;
      font-size: $font-size-large;
      font-weight: 600;
      background-color: transparent;
      cursor: pointer;
      color: $blue1;
      border-radius: $border-radius;
      height: 40px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color $anim-duration $anim-easing,
        color $anim-duration $anim-easing;

      &.selected,
      &:hover {
        color: $blue1;
        background-color: rgba($blue1, 0.1);
      }

      &:active {
        color: $blue1;
        background-color: rgba($blue1, 0.3);
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: $font-size;
        height: 30px;
        padding: 0 10px;
      }
    }
  }
}

.requestAcctFormModal {
  width: 80vw;
  height: 80vh;
  padding: 0 !important;
  overflow: hidden;

  .requestAcctForm {
    display: flex;
    flex: 1 1 auto;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.signUpFormModal {
  max-width: 460px;
  margin: 0 20px;

  .signUpForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.loggingIn {
      flex-direction: row;

      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0 15px 0 0;
      }
    }

    h3 {
      font-family: 'Vollkorn', sans-serif;
      font-size: 36px;
      line-height: 46px;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .desc {
      color: $font-color-muted;
      margin: 0;
      max-width: 335px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
    }

    .reasonsWhy {
      display: flex;
      padding-top: 15px;
      padding-bottom: 5px;

      .reason {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid $border-color;
          margin-bottom: 8px;
          padding-top: 6px;

          > span > span {
            width: 26px !important;
            height: 26px !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 !important;
            vertical-align: middle !important;
          }
        }

        .title {
          font-size: $font-size-medium;
          font-weight: 600;
          line-height: 20px;
          text-align: center;

          span {
            text-decoration: underline;
          }
        }
      }
    }

    .security {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 20px 20px 16px;
      margin: 20px 0 25px;

      h5 {
        color: $black;
        margin: 0 0 15px;
        font-size: $font-size-small;
      }

      p {
        font-size: $font-size;
        line-height: 18px;
        color: $font-color-muted;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;

      > div {
        margin: 5px;
        flex: 1 1 auto;
      }
    }

    .youAgree {
      font-size: $font-size-small;
      color: $font-color-muted;
      padding: 15px 0 0 0;
    }
  }
}
