@import '../variables.scss';

.getStartedCTAHeader {
  color: $font-color-muted;
  font-size: $font-size-large;
  text-align: center;
  margin-bottom: 25px;
  line-height: 32px;
  border-top: 1px solid $border-color;
  padding: 20px 30px 0;

  @media (max-width: $breakpoint-mobile) {
    font-size: $font-size-large;
    line-height: 26px;
  }
}

.getStartedButton {
  @include big-white-button();
  background-color: $blue1;
  flex-direction: column;
  color: $white;
  justify-content: center;
  padding: 0 80px;
  font-size: 20px;

  &:hover {
    background-color: $blue1;
  }

  .sub {
    text-transform: uppercase;
    color: #8ccbff;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .text {
    display: flex;
    align-items: center;
  }

  .arrowRightIcon {
    margin-left: 8px;
    opacity: 0.6;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: $font-size-medium;
  }
}
