@import './variables.scss';

.footer {
  display: flex;
  background: $white;
  flex: 1 0 auto;
  border-top: 1px solid $border-color;
  padding: 30px;

  &.home {
    background: transparent;
  }

  .left {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-right: auto;
  }

  .right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  .copyright {
    font-size: $font-size-small;
    color: $font-color-muted;
    height: auto;
    padding: 0 15px 10px 0;
  }

  .links {
    display: flex;
  }

  .link {
    flex: none;
    font-size: $font-size-small;
    color: $font-color-muted;
    margin-right: 15px;
  }

  .sqreen {
    width: 109px;
    height: 36px;
    display: block;
    background-image: url(../images/sqreen-logo.svg);
    background-size: 100% auto;
    text-indent: -999999px;
  }
}
