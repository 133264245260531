@import './variables.scss';

.metric {
  display: flex;
  flex-direction: column;

  .title {
    font-size: $font-size-medium;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .data {
    display: flex;
    align-items: baseline;

    &.hasDelta {
      align-items: center;
    }
  }

  .details {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 6px;
  }

  .label {
    flex: 1 1 auto;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px;
    padding-left: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.bottomLabel {
      margin-bottom: 8px;
    }
  }

  .number {
    display: flex;
    align-items: baseline;
    flex-shrink: 0;
    font-weight: 800;
    font-size: 38px;
    white-space: nowrap;

    .dollarSign {
      font-size: 22px;
      margin-right: 3px;
    }

    > span.decimal {
      font-size: 28px;
    }
  }

  .time {
    flex: none;
    display: flex;

    .divider {
      width: 1px;
      background-color: $border-color;
    }

    .colon {
      display: flex;
      align-items: center;
      margin-right: 3px;
      font-size: 28px;
      font-weight: 800;
    }

    .numWrapper {
      flex-shrink: 0;
      display: flex;
      align-items: baseline;
      margin-right: 4px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      .text {
        position: absolute;
        bottom: -1px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          font-size: 8px;
          font-weight: 600;
          color: $font-color-muted;
          background: rgba($white, 0.7);
          border-radius: 2px;
        }
      }
    }

    .label {
      padding-left: 6px;
    }
  }

  .descriptor {
    font-size: $font-size-small;
    color: $font-color-muted;
    line-height: 16px;
    padding-top: 5px;

    a {
      color: $font-color-muted;
      text-decoration: underline;
    }
  }
}
