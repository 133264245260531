@import './variables.scss';

.score {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 4px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .top {
    display: flex;
    align-items: center;
  }

  .bottom {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    color: $font-color-muted;
    line-height: 16px;
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .emoji {
    font-size: $font-size-extra-large;
    margin-right: 7px;
    flex: 0 0 auto;
  }

  .name {
    flex: 1 1 auto;
    margin-right: 10px;
  }

  .count {
    color: $font-color-muted;
    flex: 0 0 57px;
    justify-content: flex-start;
  }

  .score {
    color: $font-color-muted;
    flex: 0 0 60px;
    justify-content: flex-start;
  }

  .points {
    color: $font-color-muted;
    flex: 0 0 65px;
    justify-content: flex-start;

    > span {
      font-size: 12px;
      margin: 0 0 0 3px;
      opacity: 0.65;
    }
  }
}
