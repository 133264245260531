@import './variables.scss';

.welcomeModal {
  backdrop-filter: blur(10px);
  z-index: 999999999999 !important;

  .welcomeModalInner {
    padding: 0 !important;
  }
}

.welcomeView {
  width: auto;
  min-width: 400px;
  min-height: 160px;

  &.introView {
    max-width: 500px;

    .header h1 {
      text-align: center;
    }
  }

  &.calendarView {
    max-width: 350px;
  }

  &.goalsView {
    display: flex;
    flex-direction: column;
    max-width: 540px;
    max-height: 70vh;

    .header {
      border-bottom: 1px solid $border-color;
    }

    .content {
      overflow-y: auto;

      @supports (overflow-y: overlay) {
        overflow-y: overlay;
      }
    }
  }

  .header {
    position: relative;
    padding: 25px 30px 15px;

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 95px;
    }

    .desc {
      color: $font-color-muted;
      line-height: 18px;
      margin-top: 12px;
    }
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-bottom: -60px;
    position: absolute;
    bottom: 0;
    width: 100%;

    .nextBtn {
      span {
        opacity: 0.6;
        margin-right: 6px;
      }
    }

    > div {
      @include elevation-3();
      border-radius: 6px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .arrowRightIcon {
      margin-left: 8px;
    }
  }

  .intro {
    color: $font-color-muted;
    text-align: center;
    padding: 0 40px 40px;
    line-height: 18px;

    .badge {
      position: absolute;
      top: -28px;
      margin-left: -55px;
      left: 50%;
      width: 101px;
      height: 101px;
      background-image: url(../images/logo-markup.png);
      background-size: 100% auto;
    }

    p {
      margin: 0 0 15px 0;

      span {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .calendars {
    padding: 20px 30px;
    overflow: auto;
    max-height: 40vh;
    border-top: 1px solid $border-color;
  }

  .goals {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .welcomeView {
    min-width: auto !important;
    max-width: 90vw !important;
  }
}
