@import './variables.scss';

.meetingCost {
  .meetingCostInner {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 800;
    padding: 0 8px 0;
    height: 30px;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    > span {
      font-size: 22px;
      margin-right: 3px;
    }
  }
}
