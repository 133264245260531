@import '../variables.scss';

.howItWorks {
  border-top: 1px solid $border-color;
  border-bottom: none !important;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgba(242, 247, 255, 0.4);
  border-radius: 0;
  margin-bottom: 0 !important;

  .inner {
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .arrows {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 150px;

      .downLine {
        position: absolute;
        width: 1px;
        left: 50%;
        background-color: rgba($gray1, 0.3);
        top: 30px;
        height: 31px;
      }

      > div:first-child {
        margin-bottom: -8px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        top: 60px;
        left: 68px;
        width: calc(50% - 68px);
        background-color: rgba($gray1, 0.3);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        left: 0;
        background-color: rgba($gray1, 0.3);
        bottom: 0;
        left: 68px;
        height: 89px;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      padding: 0 30px;
      max-width: 1000px;

      .step {
        display: flex;
        align-items: center;
        padding-bottom: 120px;
        position: relative;

        &:last-child {
          padding-bottom: 0;
        }

        .content {
          display: flex;
          margin-right: 60px;
        }

        .emoji {
          @include elevation-2();
          flex: none;
          width: 75px;
          height: 75px;
          border-radius: $border-radius;
          background: $gray3;
          font-size: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 40px;
          position: relative;
          z-index: 1;
        }

        .connectingLine {
          position: absolute;
          width: 1px;
          top: 0;
          bottom: 0;
          left: 38px;
          background: rgba($gray1, 0.3);
        }

        .text {
          display: flex;
          flex-direction: column;

          h3 {
            margin: 0 0 15px;
          }

          p {
            margin-bottom: 0;
          }
        }

        .image {
          flex: none;
          width: 52%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .howItWorks {
    .inner {
      .top {
        padding: 0 20px;
        margin-bottom: 20px;
      }

      .arrows {
        display: none;
      }

      .steps {
        max-width: 100vw;
        padding: 0;

        .step {
          flex-direction: column;
          padding-bottom: 80px;

          .content {
            margin: 0 0 30px 0;
            padding: 0 20px;

            .emoji {
              width: 50px;
              height: 50px;
              font-size: 28px;
              margin-right: 20px;
            }

            .connectingLine {
              display: none;
            }
          }

          .image {
            width: 100%;
          }
        }
      }
    }
  }
}
