@import '../variables.scss';

.fakeBrowser {
  @include elevation-3();
  align-items: stretch !important;
  border-radius: $border-radius;
  max-width: 960px !important;
  background-color: rgba($gray2, 0.05);

  &.elevationOne {
    @include elevation-2();
    align-items: stretch !important;
    border-radius: $border-radius;
    max-width: 1000px !important;
  }

  .browserHeader {
    display: flex;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid $border-color;
    position: relative;

    .actions {
      position: absolute;
      top: 0;
      left: 15px;
      bottom: 0;
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: $gray2;
        margin-right: 5px;
      }
    }

    .text {
      width: 100%;
      text-align: center;
      line-height: 15px;
      font-size: $font-size-small;
      color: $font-color-muted;
      opacity: 0.75;
    }
  }

  .browserInner {
    padding: 0;
    position: relative;
  }
}
