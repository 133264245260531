@import './variables.scss';

.choosePlanModal {
  .choosePlanModalInner {
    width: calc(100vw - 40px);
    height: auto;
    min-width: 830px;
    max-width: 830px;
    padding: 0 !important;
    overflow: hidden;
    max-height: calc(100vh - 120px);
  }
}

.balanceView {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  .subNav {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    padding: 0 20px;
    border-bottom: 1px solid $border-color;

    width: 100%;

    > div {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  > .inside {
    flex: 1 1 auto;
    display: flex;
    padding: 0 20px;
    min-height: 0;
    width: 100%;
    overflow: auto;

    > .content {
      display: flex;
      align-items: flex-start;
      padding: 0 10px 0;
      max-width: 990px;
      min-height: fit-content;
      width: 100%;
      position: relative;
      margin: 0 auto;

      > .column {
        padding-top: 35px;

        h3 {
          font-size: 22px;
          margin: 0 0 8px;
          font-weight: 600;
        }

        .desc {
          color: $font-color-muted;
          margin: 0 0 22px;
          line-height: 18px;
        }
      }
    }
  }

  .quote {
    margin-bottom: 25px;
  }

  h5 {
    color: $secondary-color !important;
    margin-bottom: 15px !important;
  }

  .preferences {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-right: 70px;
    padding-bottom: 40px;
    z-index: 10;

    &.eval {
      padding: 97px 0 40px 0 !important;
    }

    .section {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        h5 {
          flex: 1 1 auto;
          margin-right: 40px;
          margin-bottom: 0 !important;
        }

        .groupPreferences > div {
          color: $font-color-muted;
          font-weight: 400;
        }
      }

      .inside {
        @include elevation-2();
        display: flex;
        flex-direction: column;
        border-radius: $border-radius;
      }
    }
  }

  .actionToTake {
    display: block;
    position: sticky;
    top: 0;

    > .arrow {
      position: absolute;
      left: -50px;
      top: 56px;
      width: 32px;
      height: 32px;
      opacity: 0.3;

      &:before {
        content: '';
        display: block;
        height: 4px;
        width: 50px;
        position: absolute;
        top: 14px;
        right: 25px;
        border-radius: 2px;
        background-color: #8a94a4;
      }

      > svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .gradeToMeet {
    margin-right: 55px;
    position: sticky;
    top: 0;
    width: 285px;
    flex: none;

    .section {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .card {
      @include elevation-2();
      border-radius: $border-radius;
      position: relative;
    }
  }

  .wellnessDenyList {
    flex: 1 1 auto;
    width: 100%;

    .header {
      display: flex;

      .text {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
    }

    .card {
      @include elevation-2();
      display: flex;
      flex-direction: column;
      border-radius: $border-radius;
    }
  }
}

.maybeWellnessBlocks {
  margin-top: 40px;
  margin-bottom: 50px;

  h5 {
    margin-bottom: 10px !important;
  }

  .desc {
    font-size: $font-size-small;
    color: $font-color-muted;
    margin-bottom: 20px;
  }

  .card {
    .block {
      display: flex;
      padding: 12px 15px;
      border-bottom: 1px solid $border-color;
      position: relative;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        display: flex;
        align-items: flex-start;
        margin-right: 10px;
      }

      .iconWrapper {
        border-radius: $border-radius;
        padding: 8px;

        &.focus {
          background-color: lighten($blue1, 40%);
        }

        &.life {
          background-color: lighten($purple2, 60%);
        }

        .questionMark {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 22px;
          font-weight: 700;
          padding-top: 3px;
        }
      }

      .info {
        flex-direction: column;
      }

      .label {
        display: flex;
        align-items: center;
        font-size: $font-size;
        font-weight: 600;
        margin-bottom: 0;
      }

      .ruleAndTime {
        display: flex;
        align-items: center;
        font-size: $font-size-small;
        color: $font-color-muted;
        min-height: 20px;

        > div {
          margin-right: 10px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        flex: none;
        margin-left: auto;
      }

      // Mobile styles
      @media (max-width: $breakpoint-mobile) {
        .actions {
          position: absolute;
          top: 5px;
          right: 10px;
        }
      }
    }
  }
}

.goToAccount {
  position: fixed;
  top: 15px;
  right: 15px;
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .choosePlanModal {
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;

    .choosePlanModalInner {
      min-width: 100%;
      width: 100%;
    }
  }

  .balanceView {
    > .inside {
      padding: 0 30px 30px;

      .content {
        flex-direction: column;
        padding: 0;
      }

      .gradeToMeet,
      .actionToTake {
        position: relative;
        margin: 0;
        width: 100%;

        .arrow {
          display: none;
        }
      }

      .actionToTake {
        padding-top: 0 !important;
      }
    }

    .preferences {
      flex: none;
      padding: 0;
      margin-bottom: 40px !important;

      &.eval {
        padding-top: 40px !important;
        padding-bottom: 0 !important;
      }

      > .section {
        > .header {
          flex-direction: column;
          align-items: flex-start;

          h5 {
            margin: 0 0 6px !important;
          }

          span {
            margin-left: -5px;
          }
        }

        > .inside {
          flex-direction: column;
        }
      }
    }
  }
}
