@import '../variables.scss';

.noTrackingPopup {
  @include elevation-3();
  position: fixed;
  left: 20px;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  z-index: 99;
  border-radius: $border-radius;
  max-width: 330px;

  .emojis {
    font-size: 42px;

    span > span {
      margin: 0 10px 0 0 !important;
    }
  }

  h2 {
    font-family: 'Vollkorn', sans-serif;
    font-size: 24px;
    margin: 2px 0 8px;

    @media (max-width: $breakpoint-mobile) {
      font-size: 24px;
    }
  }

  p {
    font-size: $font-size !important;
    line-height: 18px !important;
    margin-bottom: 15px !important;
  }
}
