@import './variables.scss';

.alertWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999999999;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  transition: opacity $anim-duration $anim-easing;

  .alert {
    @include elevation-3();
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    z-index: 999;
    padding: 30px;
    border-radius: 6px;
    min-width: 300px;
    max-width: 500px;
    transition: opacity $anim-duration $anim-easing,
      visibility $anim-duration $anim-easing;

    &.visible {
      opacity: 1;
      pointer-events: visible;
    }

    .alertTitle {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
      font-size: $font-size-medium;
      line-height: 22px;
      font-weight: 600;
    }

    .alertText {
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 20px;
      line-height: 18px;
      color: $font-color-muted;
    }

    .actions {
      display: flex;
      align-items: center;

      > div {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bkg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, 0.25);
  }
}
