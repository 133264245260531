@import './variables.scss';

.invoices {
  padding-top: 10px;
  margin-bottom: 10px;

  &.empty {
    padding: 0;
    margin: 0;
    color: $font-color-muted;
  }

  .header {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;

    span {
      flex: 1 1 auto;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;

      &:last-child {
        flex: none;
        width: 90px;
      }
    }
  }

  .row {
    display: flex;
    padding: 15px 0;
    position: relative;

    .date {
      flex: 1 1 0;
      display: flex;
      color: $font-color-muted;

      > * {
        flex: none;
      }

      span {
        margin: 0 5px;
      }
    }

    .what {
      flex: 1 1 0;
      display: flex;
    }

    .download {
      flex: none;
      width: 90px;
      text-align: right;
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .invoices {
    padding-top: 0;
    margin-top: -10px;

    .header {
      display: none;
    }

    .row {
      padding-bottom: 20px;

      .date {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
