@import './variables.scss';

.wcstFloater {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  position: fixed;
  z-index: 99999999999999;
  background: rgba($black, 0.95);
  border-radius: $border-radius;
  color: $white;
  padding: 8px 8px 6px;

  .label {
    font-size: 10px;
    opacity: 0.6;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 6px;
  }
}
