@import './variables.scss';

.presentMeetingBtn {
  margin-left: 10px;
}

.presentModal {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  max-width: none !important;
  min-width: none !important;
  height: 100% !important;
  min-height: none !important;
  max-height: none !important;
  border-radius: 0 !important;
  transform: translateX(0) !important;
  padding: 0 !important;
  overflow: hidden;

  :global(.fullscreen) {
    height: 100%;
    width: 100%;
  }
}

.presentView {
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;

  .topBar {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    width: 100%;
    color: $font-color-muted;
    background-color: $white;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px 0 10px;
      position: relative;

      .title {
        @include text-overflow-ellipsis();
        margin-right: 10px;
        border-right: 1px solid $border-color;
        padding-right: 10px;
      }

      .elapsedTime {
        flex: none;
      }

      .objectives {
        display: block;
        position: absolute;
        left: 10px;
        top: calc(100% + 3px);

        label {
          margin-right: 6px;
        }
      }
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .divider {
        height: 16px;
        width: 1px;
        margin: 0 5px;
        background-color: $border-color;
      }
    }
  }

  .meetingPrep {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      font-size: 62px;
      line-height: 72px;
      font-weight: 700;
      margin: 0 0 20px;
    }

    .miscInfo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 40vw;
      min-width: 600px;
      border-top: 1px solid $border-color;
      padding-top: 30px;
      margin: 40px 0 20px;

      .group {
        padding: 20px 40px 20px;
      }
    }

    .startMeetingBtn {
      width: 300px;
    }
  }

  .viewInProgress,
  .viewCompleted {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font-size-extra-large;
    outline: 0;

    h1 {
      text-align: center;
      font-size: 62px;
      line-height: 72px;
      font-weight: 700;
      margin: 0 0 10px;
      padding: 0 30px;
    }

    .miscInfo {
      font-size: $font-size-medium;
      margin-bottom: 40px;
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      &::before,
      &::after {
        content: '';
        height: 1px;
        background-color: $border-color;
        flex: 1 1 auto;
      }

      h5 {
        font-size: $font-size;
        color: $font-color-muted;
        text-transform: uppercase;
        margin: 0 15px;
        flex: none;
      }
    }

    h6 {
      font-size: $font-size-small;
      color: $secondary-color;
      text-transform: uppercase;
    }

    .objectives {
      display: flex;
      margin-bottom: 40px;

      label {
        margin-right: 8px;
      }
    }

    .preWorkItems {
      position: absolute;
      right: 0;
      border-left: 1px solid $border-color;
      padding: 15px 30px;
      background-color: $white;

      h5 {
        margin-bottom: 10px;
      }

      .item {
        font-size: $font-size-medium;
        padding: 5px 0;
      }
    }

    .agendaItems {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      max-width: 600px;

      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        position: relative;
        padding: 0 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &.discussing {
          flex-direction: column;
          align-items: center;
          margin: 50px 0;
          width: 100vw;

          .title {
            text-align: center;
            font-size: 62px;
            line-height: 72px;
            margin: 0 0 10px;
            font-weight: 600;
            padding: 0;
            max-width: 800px;
          }

          .duration {
            width: auto;
            margin: 0;
          }
        }

        &.complete {
          opacity: 0.3;

          .duration.isDifferent span {
            text-decoration: line-through;
          }
        }

        &.meetingComplete {
          opacity: 1;

          .duration {
            &.isDifferent span {
              opacity: 0.4;
            }

            &.isShorter .actualTime {
              color: $green2;
            }

            &.isLonger .actualTime {
              color: $red2;
            }
          }
        }

        .currentIndicator {
          position: absolute;
          width: 200px;
          left: 50%;
          margin-left: -100px;
          top: -30px;
          text-align: center;

          h6 {
            margin: 0;
          }
        }

        .title {
          display: flex;
          align-items: flex-start;
          padding-right: 25px;

          .accessory {
            flex: none;
            width: 20px;
            margin-right: 6px;
          }
        }

        .duration {
          flex: none;
          width: 70px;
          margin-left: auto;
          color: $font-color-muted;
          position: relative;

          .actualTime {
            position: absolute;
            left: 100%;
            width: 70px;
            top: 0;
          }

          .slash {
            margin: 0 8px;
          }
        }
      }
    }

    .preWorkItems {
      display: flex;
      flex-direction: column;
      max-width: 200px;
    }

    .prevNextBtns {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      > div:first-child {
        margin-right: 10px;
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .presentMeetingBtn {
    position: absolute;
    top: 50px;
    right: 20px;
    margin: 0;
  }

  .presentModal.mobile.overrides {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    max-width: none !important;
    max-height: none !important;

    h1 {
      font-size: 58px;
      line-height: 62px;
      margin-bottom: 15px;
    }

    .topBar {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 5px;
      width: auto;
      z-index: 9999;

      .center {
        display: none;
      }

      .right {
        margin-left: auto;
      }
    }

    .meetingPrep {
      padding: 60px 0;

      .info {
        display: flex;
        flex-direction: column;
        min-width: auto;
        width: 100%;

        .group {
          padding: 20px 30px 0;
        }
      }
    }

    .viewInProgress,
    .viewCompleted {
      font-size: $font-size-large;

      .agendaItems {
        .item {
          .title {
            line-height: 20px;
          }

          &.discussing {
            padding: 0 30px;

            .title {
              font-size: 48px;
              line-height: 52px;
            }
          }

          &.complete {
            .duration {
              span {
                display: none;
              }

              .actualTime {
                position: static;
                left: auto;
                top: auto;
              }
            }
          }
        }
      }
    }
  }
}
