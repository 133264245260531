@import './variables.scss';

.row {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid $border-color;
  padding: 15px;

  &:last-child {
    border-bottom: none;
  }

  .main {
    flex: 1 1 auto;
    display: flex;
  }

  .content {
    flex: 1 1 0;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    .top {
      display: flex;
      align-items: center;

      .name {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        font-weight: 600;
        padding-right: 10px;
        line-height: 15px;
      }

      .help {
        position: relative;
        top: auto;
        left: auto;
      }
    }

    .description {
      color: $font-color-muted;
      font-size: $font-size-small;
      line-height: 16px;
      margin-top: 5px;
    }

    .example {
      @include elevation-0();
      font-size: $font-size-small;
      line-height: 16px;
      align-self: flex-start;
      border-radius: 2px;
      color: $font-color-muted;
      height: auto;
      padding: 3px 6px 3px 5px;
      margin-top: 8px;

      &::before {
        content: 'Ex:';
        color: rgba($font-color-muted, 0.6);
        margin-right: 4px;
      }
    }
  }

  .value {
    flex: 1.5 1 0;
    display: flex;

    > .choice {
      flex: 2 1 0;
      display: flex;
      align-items: center;
      margin-right: auto;

      .delta {
        opacity: 0.35;
        text-decoration: line-through;
        text-decoration-color: $black;
        font-weight: 600;
        margin-right: 10px;
        font-size: 16px;
      }

      .num {
        font-size: $font-size-extra-large;
        font-weight: 800;
        position: relative;

        span {
          font-weight: 400;
          margin-right: 3px;
          font-size: 18px;
        }
      }

      .type {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 700;
        margin-left: 4px;
      }
    }

    > .weight {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: $font-color-muted;

      &.none {
        text-decoration: line-through;
        opacity: 0.4;
      }

      span {
        font-size: $font-size-small;
        margin: 0 0 -2px 4px;
      }
    }
  }

  .editWrapper {
    flex: none;
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  .subRoles {
    border-top: 1px solid $border-color;
    padding-top: 15px;
    margin-top: 15px;

    .subRole {
      display: flex;
      margin-bottom: 16px;

      .description {
        color: $font-color-muted;
        font-size: $font-size-small;
        line-height: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .status {
        margin-right: 8px;
      }

      .info {
        display: flex;
        flex-direction: column;

        .title {
          font-size: $font-size-small;
          font-weight: 600;
          margin-bottom: 6px;
        }
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .row {
    .main {
      flex-direction: column;

      .content {
        margin: 0 0 10px;
      }
    }
  }
}
