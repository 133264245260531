@import './variables.scss';

.delta {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-top: 7px;

  &.success .deltaNum {
    color: $green2;
  }

  &.danger .deltaNum {
    color: $intent-danger;
  }

  .deltaNum {
    flex: none;
    display: flex;
    height: 16px;
    line-height: 18px;
  }

  .compLabel {
    align-self: flex-end;
    color: $font-color-muted;
    font-weight: 400;
    margin-left: 4px;
    font-size: $font-size-small;
  }
}
