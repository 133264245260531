@import './variables.scss';
@import './review.module.scss';

.roiChartStats {
  @include review-styles-section();
  flex: 1 1 auto;

  .card {
    display: flex;
    flex-direction: column;
    height: 400px;
    position: relative;
    padding: 20px 15px 15px;

    .whoCanSeeThis {
      position: absolute;
      top: 5px;
      right: 5px;

      .text {
        font-size: 10px;
        font-weight: 600;
        color: $white;
        background-color: $black;
        opacity: 0.8;
        border-radius: 2px;
        padding: 3px 3px 1px;
      }
    }
  }
}
