$black: #002e56;
$white: #ffffff;

$blue1: #1f78f7;
$blue2: #4bbcfd;
$green1: #45ddb8;
$green2: #0eb48e;
$yellow1: #fed93a;
$yellow2: darken($yellow1, 15%);
$orange1: #ff8a00;
$orange2: darken($orange1, 15%);
$purple1: #d597d8;
$purple2: #832487;
$red1: #ff3146;
$red2: #b20012;

$gray0: #234d71;
$gray1: #93a5c1;
$gray2: lighten($gray1, 25%);
$gray3: lighten($gray2, 25%);

$intent-success: $green1;
$intent-caution: $yellow1;
$intent-warning: $orange1;
$intent-danger: $red1;

$primary-color: $black;
$secondary-color: $blue1;

$icon-color: $gray1;

$font-color: $black;
$font-color-muted: darken($gray1, 20%);

$border-color: lighten($gray2, 3%);
$link-color: $blue1;
$border-radius: 4px;

$font-size-small: 12px;
$font-size: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-extra-large: 22px;

$anim-duration: 0.3s;
$anim-easing: cubic-bezier(0.25, 0.1, 0.25, 1);

$breakpoint-mobile: 812px; // 812 is horizontal iPhone
$breakpoint-small: 950px;
$breakpoint-medium: 1200px;

@mixin bkg-gradient() {
  background-image: linear-gradient(
    0deg,
    #ffffff 0%,
    rgba(242, 247, 255, 0.45) 100%
  );
}

@mixin elevation-0() {
  background-color: $white;
  box-shadow: 0 0 0 1px rgba($gray0, 0.1);
}

@mixin elevation-1() {
  background-color: $white;
  box-shadow: 0px 1px 0px rgba($gray0, 0.1), 0px 2px 4px rgba($gray0, 0.15);
}

@mixin elevation-2($color: $gray0) {
  background-color: $white;
  box-shadow: 0px 1px 0px rgba($color, 0.1), 0px 2px 4px rgba($color, 0.15),
    0 10px 50px 0 rgba($color, 0.1);
}

@mixin elevation-3() {
  background-color: $white;
  box-shadow: 0px 1px 0px rgba($gray0, 0.1), 0px 3px 6px rgba($gray0, 0.15),
    0px 10px 70px rgba($gray0, 0.4);
}

@mixin white-card-bkg() {
  @include elevation-2();
  border-radius: 20px 20px 0 0;
}

@mixin click-elevation() {
  background-color: rgba($white, 0.7);
  box-shadow: inset 0 0 0 1px rgba($gray0, 0.2), 0 1px 6px 0 rgba($gray0, 0.05),
    0 3px 6px 0 rgba($gray0, 0.05);
  border-radius: $border-radius;
  transition: background-color $anim-duration $anim-easing;

  &:hover {
    background-color: rgba($gray2, 0.3) !important;
  }
}

@mixin text-overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin big-white-button {
  @include elevation-2();
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 30px;
  height: 60px;
  font-size: $font-size-large;
  font-weight: 600;
  padding: 0 30px;
  cursor: pointer;
  transition: box-shadow $anim-duration $anim-easing;

  &:hover {
    @include elevation-3();
  }

  &:active {
    @include elevation-2();
  }
}

@mixin google-sign-in-button {
  display: flex;
  align-items: center;

  .logo {
    display: block;
    background-image: url(../images/google-logo.svg);
    background-size: 100% auto;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .text {
    font-size: $font-size;
    line-height: 19px;
    height: 16px;
  }
}

@mixin outlook-sign-in-button {
  display: flex;
  align-items: center;

  .logo {
    display: block;
    background-image: url(../images/microsoft-logo.svg);
    background-size: 100% auto;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .text {
    font-size: $font-size;
    line-height: 19px;
    height: 16px;
  }
}

@mixin running-text() {
  .inner {
    max-width: 900px;
    padding: 50px 30px;
    margin: 0 auto;
    width: 100%;

    > hr {
      margin: 0 0 20px !important;
    }

    > h1 {
      font-family: 'Vollkorn', sans-serif;
      font-weight: 600 !important;
      margin-bottom: 20px !important;
      font-size: 30px !important;
    }

    > h2 {
      margin: 0 0 20px !important;
    }

    > h3 {
      margin: 0 0 20px !important;
    }

    > h4 {
      margin: 0 0 20px !important;
    }

    > h5 {
      color: $black !important;
      margin: 0 0 20px !important;
    }

    > ul {
      margin: 0 0 20px !important;
    }

    > p,
    > ul,
    li {
      font-size: $font-size-medium;
      line-height: 22px;
    }

    > p {
      margin: 0 0 20px !important;
    }
  }
}
