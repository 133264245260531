@import './variables.scss';

.loadingIndicator {
  position: fixed;
  z-index: 999999;
  bottom: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  padding: 10px 0;
  box-shadow: 0 1px 0 rgba($black, 0.1), 0 5px 16px rgba($black, 0.25);
  border-radius: 20px 20px 0 0;
  width: 160px;
  margin-left: -80px;
  transform: translateY(30px);
  opacity: 0;
  pointer-events: none;
  transition: transform $anim-duration $anim-easing,
    opacity $anim-duration $anim-easing;

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }

  .indicator {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 6px;
  }

  .text {
    color: $font-color-muted;
  }
}
