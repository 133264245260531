@import './variables.scss';

.pullQuote {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 15px;
  margin-top: 15px;

  .mark {
    color: $yellow1;
    font-size: 72px;
    font-weight: 900;
    height: 20px;
    margin-bottom: 10px;
    margin-top: -20px;
    line-height: 52px;
    padding: 0 5px;
    background-color: $white;
  }

  .text {
    color: $font-color-muted;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    font-style: italic;
  }

  .author a {
    color: $font-color-muted;
    text-decoration: underline;
  }
}
