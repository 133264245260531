@import './variables.scss';

.pricing {
  display: flex;
  align-items: center;
  font-weight: 600;

  > span {
    margin: 0 5px;
  }

  .status {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .unlocked {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba($blue1, 0.1);
  }

  .plans {
    flex: none;
    display: flex;
    align-items: center;
    border-top: 1px solid $border-color;
    padding-top: 8px;
    margin-top: 10px;

    .perMonth {
      color: $font-color-muted;
      padding-right: 8px;
      line-height: 27px;
      height: 26px;
      font-weight: 400;
    }

    .unsubButton {
      height: 26px;
      padding: 6px 7px 4px;
    }
  }

  .btm {
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    opacity: 0;

    .text {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;

      strong {
        font-weight: 600;
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;
}
