@import '../variables.scss';

.organizerStatsPreview {
  > div {
    @media (max-width: $breakpoint-mobile) {
      transform: scale(0.5);
      margin: -120px 0 -130px -110px;

      > div {
        flex: none;
      }
    }
  }
}
