@import './variables.scss';

.previewReminderOuterModal {
  align-items: center !important;
  justify-content: center !important;
  z-index: 9999999;
}

.previewReminderModal {
  display: flex;
  flex-direction: column;
  left: auto !important;
  top: auto !important;
  width: 690px !important;
  max-width: 690px !important;
  min-width: 690px !important;
  height: auto !important;
  min-height: 10vh !important;
  max-height: 80vh !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 0 !important;

  .header {
    padding: 30px;
    border-bottom: 1px solid $border-color;

    h1 {
      margin-bottom: 10px;
    }

    .desc {
      color: $font-color-muted;
      line-height: 18px;
    }
  }

  .email {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    // Mobile styles
    @media (max-width: $breakpoint-mobile) {
      img {
        width: calc(100% + 300px);
      }
    }
  }
}
