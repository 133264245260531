@import './variables.scss';
@import './review.module.scss';

.summaryStats {
  position: relative;
  @include review-styles-section();

  .inviteMembers {
    position: absolute;
    top: 50px;
    right: 0;
  }

  .card {
    min-height: 83px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    padding: 15px 25px;
    font-weight: 200;
    min-width: 200px;
    max-width: 350px;

    strong {
      font-weight: 600;
    }

    .teammateTooltip {
      background-image: linear-gradient(90deg, $black 0, $black);
      background-position: 0 1.2em;
      background-repeat: repeat-x;
      background-size: 1px 1px;
    }

    .inviteStatusTag {
      > div {
        height: 16px;
        line-height: 18px;
        font-weight: 400;
      }
    }
  }

  .content {
    flex: none;
    display: flex;
    align-items: center;
    border-left: 1px solid $border-color;
    padding: 20px;

    .statWrapper {
      flex: none;
      display: flex;
      align-items: center;
      padding-right: 12px;

      &:last-child {
        padding-right: 0;
      }
    }

    .stat {
      flex: none;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      font-size: 42px;
      font-weight: 800;
      padding: 0 8px 0;
      border-radius: $border-radius;
      box-shadow: 0 0 0 1px $border-color;

      &.danger {
        background-color: rgba($intent-danger, 0.15);
        color: darken($intent-danger, 20%);
        box-shadow: none;
      }

      &.success {
        background-color: rgba($intent-success, 0.15);
        color: darken($intent-success, 20%);
        box-shadow: none;
      }

      > span {
        font-size: 16px;
        margin-left: 6px;
      }
    }

    .label {
      flex: none;
      margin-left: 12px;
    }

    .overallCosts {
      pointer-events: all;

      > .stat {
        display: flex;
        justify-content: center;
        height: 62px;
      }

      .totals {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .avgCost {
        display: flex;
        flex-direction: column;
        border-left: 1px solid $border-color;
        margin: 0 5px 0 8px;
        padding-left: 8px;

        .num {
          font-size: 18px;
        }

        .label {
          font-size: 10px;
          font-weight: 800;
          margin: 0;
        }
      }

      .editSalary {
        margin-left: auto;
      }

      .meetingCost {
        font-size: 38px;

        > div {
          font-size: 18px;
        }
      }

      .basedOnSalary {
        font-size: $font-size-small;
        color: $font-color-muted;
        font-weight: 400;
        margin-top: -3px;
        white-space: nowrap;

        > * {
          font-weight: 400 !important;
        }
      }
    }
  }

  .descriptor {
    text-align: center;
    line-height: 18px;
    padding-top: 25px;
    color: $font-color-muted;

    strong {
      color: $font-color;
      font-weight: 600;
    }

    span {
      text-transform: lowercase;
    }
  }

  @mixin small-width {
    padding: 0;

    h5 {
      margin-bottom: 15px !important;
    }

    .inviteMembers {
      display: none;
    }

    .card {
      flex-direction: column;

      .title {
        padding: 18px 20px 14px;
      }

      .content {
        align-items: normal;
        flex-direction: column;
        border-left: none;
        padding: 15px;
        padding-top: 0;

        .top {
          display: flex;
          align-items: center;
        }

        .bottom {
          display: flex;
          align-items: center;
          margin-top: 7px;

          &:empty {
            display: none;
          }
        }

        .statWrapper {
          flex: 1 1 auto;
          margin-bottom: 0;
          padding-right: 7px;

          &:last-child {
            padding-right: 0;
          }

          &.meetings {
            flex-shrink: 1;
            min-width: 0;

            .stat {
              flex-shrink: 1;
              min-width: 0;
            }
          }

          > .stat {
            width: 100%;
          }
        }
      }
    }

    .descriptor {
      padding-top: 15px;
    }
  }

  &.isColumnLayout {
    @include small-width();
  }

  @media (max-width: $breakpoint-mobile) {
    &:not(.isForMarketing) {
      @include small-width();
    }
  }
}
