@import './variables.scss';

.tag {
  font-size: $font-size;
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius;
  padding: 0 6px;
  height: 22px;
  flex: 0 0 auto;
  background-color: rgba($gray1, 0.1);
  color: rgba($gray0, 0.8);

  &.danger {
    background-color: rgba($intent-danger, 0.1);
    color: $red1;
  }

  &.caution {
    background-color: rgba($intent-caution, 0.15);
    color: darken($intent-caution, 25%);
  }

  &.warning {
    background-color: rgba($intent-warning, 0.15);
    color: $intent-warning;
  }

  &.success {
    background-color: rgba($intent-success, 0.15);
    color: darken($intent-success, 15%);
  }

  &.primary {
    background-color: rgba($blue1, 0.15);
    color: darken($blue1, 15%);
  }

  &.minimal {
    @include elevation-0();
    background-color: transparent;
  }

  &.small {
    height: 20px;
    padding: 0 4px;

    .text {
      font-size: $font-size-small;
      line-height: 14px;
      height: 12px;
    }
  }

  &.large {
    height: 30px;
    padding: 0 8px;

    .text {
      font-size: $font-size-large;
      line-height: $font-size-large;
    }
  }

  &.circle {
    display: flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding: 0;

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .icon {
    display: flex;
  }

  .text {
    font-weight: 600;
    line-height: 17px;
  }

  .icon + .text {
    margin-left: 2px;
  }
}

.button {
  @include click-elevation();
  font-family: 'Catamaran', sans-serif;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  font-weight: 600;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background-color $anim-duration $anim-easing;

  &.isLoading {
    > * {
      opacity: 0;
    }

    > .loader {
      opacity: 1 !important;
    }
  }

  &.minimal {
    background-color: transparent;
    box-shadow: none;

    &.active {
      background-color: rgba($blue1, 0.1);

      .text {
        color: $blue1;
      }
    }

    &.success {
      background-color: transparent !important;

      &:hover,
      &.isLoading {
        background-color: rgba($intent-success, 0.1) !important;
      }

      .text {
        color: $intent-success !important;
      }

      .loader path {
        fill: $intent-success;
      }
    }

    &.warning {
      background-color: transparent !important;

      &:hover,
      &.isLoading {
        background-color: rgba($intent-warning, 0.1) !important;
      }

      .text {
        color: $intent-warning !important;
      }

      .loader path {
        fill: $intent-warning;
      }
    }

    &.danger {
      background-color: transparent !important;

      &:hover,
      &.isLoading {
        background-color: rgba($red1, 0.1) !important;
      }

      &:active {
        background-color: rgba($red1, 0.2) !important;
      }

      .text {
        color: $red1 !important;
      }

      .loader path {
        fill: $red1;
      }
    }
  }

  &.primary {
    background-color: $blue1 !important;

    &:hover {
      background-color: darken($blue1, 10%) !important;
    }

    &:active {
      background-color: darken($blue1, 16%) !important;
    }

    &.minimal {
      background: none !important;

      &:hover,
      &.isLoading {
        background-color: rgba($blue1, 0.1) !important;

        .loader path {
          fill: $blue1;
        }
      }

      .text {
        color: $blue1 !important;
      }
    }

    .text {
      color: $white !important;
    }

    .loader path {
      fill: $white;
    }
  }

  &.success {
    background-color: $green2 !important;

    &:hover {
      background-color: darken($green2, 5%) !important;
    }

    .text {
      color: $white !important;
    }

    .loader path {
      fill: $white;
    }
  }

  &.danger {
    background-color: $red1 !important;

    &:hover {
      background-color: darken($red1, 8%) !important;
    }

    &:active {
      background-color: darken($red1, 16%) !important;
    }

    .text {
      color: $white !important;
    }

    .loader path {
      fill: $white;
    }
  }

  &.centered {
    justify-content: center;
  }

  &.fill {
    flex: 1 1 auto;
  }

  &.small {
    height: 24px;
    padding: 0 5px;

    .text {
      font-size: $font-size-small;
    }
  }

  &.large {
    height: 40px;
    padding: 0 15px;
    font-weight: 700;

    .text {
      font-size: $font-size-large;
      line-height: 18px;
      height: $font-size-large;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.shouldLookDisabled {
    opacity: 0.5;
    text-decoration: line-through;
    text-decoration-color: rgba($black, 1);
  }

  > .icon {
    & + .text {
      margin-left: 8px;
    }

    &.iconRight {
      margin-left: 5px;
    }

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    display: inline-block;
    color: $primary-color;
    line-height: 16px;
    height: 14px;
    font-size: $font-size;
    text-decoration: none;
    pointer-events: none;
  }
}

.link {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: opacity $anim-duration $anim-easing;

  .text {
    text-decoration: underline;
    color: $font-color-muted;
  }

  &.small .text {
    font-size: $font-size-small;
  }

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.inputGroup {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: $white;
  box-shadow: inset 0 0 0 1px rgba($gray1, 0.5),
    inset 0px 2px 6px rgba($gray1, 0.05);

  &.small {
    height: 24px;

    .input input {
      padding: 0 10px;
      font-size: $font-size-small;
    }
  }

  &.large {
    height: 40px;
  }

  &.hasError {
    background-color: rgba($red1, 0.04);
    box-shadow: inset 0 0 0 1px rgba($red1, 0.6),
      inset 0 0 0 1px rgba($gray1, 0.5), inset 0px 2px 6px rgba($gray1, 0.05);
  }

  * {
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  > * {
    display: flex;
    align-items: center;
    margin-left: -1px;
    border-right: 1px solid rgba($gray1, 0.5);
    height: 100% !important;

    &:first-child {
      margin-left: 0;
    }
  }

  .input {
    flex: 1 1 auto;

    input {
      padding: 0 0 0 10px;
      height: 30px;
      line-height: 33px;
    }
  }
}

.input {
  input {
    appearance: none;
    font-size: $font-size;
    font-family: 'Catamaran', sans-serif;
    height: 40px;
    background-color: $white;
    box-shadow: inset 0 0 0 1px rgba($gray0, 0.1),
      inset 0 1px 4px 0 rgba($gray0, 0.15);
    border-radius: $border-radius;
    border: none;
    width: 100%;
    padding: 0 15px;
    outline: none;
    transition: box-shadow $anim-duration $anim-easing;

    &:focus {
      box-shadow: inset 0 0 0 1px rgba($blue1, 0.4),
        inset 0 1px 4px 0 rgba($blue1, 0.15);
    }
  }
}

.selectInput {
  @include click-elevation();
  display: flex;
  position: relative;
  z-index: 6;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      background-color: transparent !important;
    }

    > div > div:first-of-type > div > div {
      color: $font-color;
      opacity: 0.6;
    }
  }

  > div {
    width: 100%;
    border: none;

    > div:first-of-type {
      border: none;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;

      > div {
        line-height: 19px;
        padding-left: 12px;
        font-weight: 600;
      }
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.navIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  &.small {
    margin: -13px 0 0 -13px;
    width: 26px;
    height: 26px;
  }

  &.inline {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
  }

  path {
    fill: $gray2;
  }
}

.errorStateWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($white, 0.9);

  &.inline {
    flex: 1 1 auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background: transparent;
  }
}

.errorState {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  padding: 30px;

  .title {
    display: flex;
    align-items: center;
    font-size: $font-size-large;
    font-weight: 700;
    text-align: center;

    span {
      margin-right: 6px;
    }
  }

  .description {
    color: $font-color-muted;
    text-align: center;
    margin-top: 15px;
    line-height: 18px;

    &:empty {
      display: none;
    }
  }

  .action {
    margin-top: 15px;
  }
}

.menuHeader {
  display: flex;
  width: 100%;
  padding: 5px;
  color: $font-color-muted;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $font-size-small;
}

.menuItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.menuItem {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px 0 6px;
  border-radius: $border-radius;
  transition: background-color $anim-duration $anim-easing;

  &:hover {
    background: $gray2;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: line-through;
  }

  > .icon {
    flex: none;
    margin-right: 8px;
  }

  .text {
    flex: none;
    font-weight: 600;
    line-height: 18px;
    height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.danger {
    .text {
      color: $intent-danger;
    }
  }
}

.menuDivider {
  width: 100%;
  margin: 5px 5px 8px;
  height: 1px;
  background-color: $border-color;
}

.checkboxWrapper {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.6;

    .checkbox {
      pointer-events: none;
    }
  }

  .disabledText {
    display: flex;
    align-items: center;
  }

  .checkbox {
    @include click-elevation();
    flex: none;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: $border-radius;
    margin-right: 10px;
    cursor: pointer;

    &.selected {
      background: $blue1;

      &:hover {
        background: $blue1 !important;
      }

      > div {
        display: block;
      }
    }

    > div {
      display: none;
      margin: 1px 0 0 2px;
    }
  }

  .text {
    line-height: 17px;

    span {
      font-weight: 500;
    }
  }
}

.segmentedControl {
  display: flex;
  height: 40px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.vertical {
    flex-direction: column;
    height: auto;

    .option {
      border-top: 1px solid $border-color;
      box-shadow: none;
      background: none;
      border-radius: 0;
      height: 40px;
      margin: 0;
      justify-content: flex-start;
      padding: 0 10px 0 5px;
      user-select: none;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.selected {
        background: none;
      }
    }
  }

  .option {
    @include click-elevation();
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -20px;
      left: -20px;
      bottom: 0;
      background-color: transparent;
      transition: background-color $anim-duration $anim-easing;
    }

    &:hover {
      background: none !important;

      &::after {
        background-color: rgba($gray2, 0.3) !important;
      }
    }

    > div {
      z-index: 2;
    }

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      background: rgba($green1, 0.2);
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    }

    > .check {
      display: none;
    }

    > .text {
      flex: 1 1 auto;
      display: flex;
      line-height: 13px;
      font-weight: 600;
      align-items: center;

      > .leftLabel {
        color: $font-color-muted;
        margin-right: 5px;
        font-weight: 400;
      }

      > .rightLabel {
        color: $font-color-muted;
        margin-left: 5px;
        font-weight: 400;
      }

      > .descriptorTag {
        margin-left: auto;
        margin-right: -10px;
      }
    }
  }
}

.timeRangePicker {
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .input {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100% !important;

    .picker {
      flex: 1 1 0;
      display: flex;
      box-shadow: inset 0 0 0 1px rgba($gray0, 0.1),
        inset 0 1px 4px 0 rgba($gray0, 0.15);
      height: 100%;
      border-radius: $border-radius;
      transition: background-color $anim-duration $anim-easing;

      &:hover {
        background-color: rgba($gray2, 0.35) !important;
      }

      &.invalid {
        background-color: rgba($intent-danger, 0.05);
        box-shadow: inset 0 0 0 1px rgba($intent-danger, 0.5),
          inset 0px 2px 6px rgba($intent-danger, 0.05);

        &:hover {
          background-color: rgba($intent-danger, 0.05) !important;
        }
      }
    }

    .time {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 13px;
      height: 100%;
    }

    .icon {
      flex: none;
      margin: 0 8px;
    }
  }
}

.callout {
  @include elevation-0();
  border-radius: $border-radius;
  padding: 8px 10px;
  font-size: $font-size-small;
  line-height: 16px;
  color: $font-color-muted;
  background-color: transparent;

  &.caution {
    background-color: lighten($intent-caution, 35%);
    color: darken($intent-caution, 38%);
    box-shadow: 0 0 0 1px rgba(darken($intent-caution, 28%), 0.3);
  }
}

.quote {
  flex: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.small {
    .text {
      font-size: 18px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .mark {
    flex: none;
    color: $yellow1;
    font-size: 82px;
    font-weight: 800;
    height: 30px;
    line-height: 74px;
    overflow: hidden;
    margin-left: -2px;
    margin-right: 10px;
  }

  .text {
    position: relative;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
    z-index: 1;
  }

  .sub {
    color: $font-color-muted;
    padding-left: 44px;
    margin-top: 4px;
    line-height: 18px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .desc {
    color: $font-color-muted;
    font-size: $font-size-small;
    margin-bottom: 14px;
    line-height: 16px;
  }

  .radio {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      line-height: 17px;
      margin-bottom: 10px;

      input {
        margin-right: 8px;
      }

      strong {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.multiTagInput {
  padding-left: 5px;
  padding-top: 5px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  overflow: hidden;
  opacity: 1;
  transition: opacity $anim-duration $anim-easing;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:global(.react-tagsinput--focused) {
    border-color: $blue1;
  }

  :global(.react-tagsinput-tag) {
    font-family: 'Catamaran', sans-serif;
    display: inline-flex;
    border: none;
    background-color: rgba($blue1, 0.1);
    color: $blue1;

    &:global([is-disabled='true']) {
      background-color: rgba($gray1, 0.1);
      color: rgba($gray0, 0.8);
    }

    :global(.react-tagsinput-remove) {
      content: url(../images/icon-small-cross.svg);
      display: block;
      padding: 3px;
      margin-left: 4px;
      opacity: 0.6;
      transition: opacity $anim-duration $anim-easing;

      &:hover {
        opacity: 1;
      }
    }
  }

  :global(.react-tagsinput-input) {
    width: 100%;
    font-family: 'Catamaran', sans-serif;
  }
}

.dollarSign {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
}
