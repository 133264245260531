@import '../variables.scss';

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  padding: 0;

  h1[data-header='one'] {
    font-family: 'Vollkorn', sans-serif;
    font-size: 58px;
    line-height: 66px;
    margin: 0 0 30px;
    text-align: center;

    span {
      font-weight: 700;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 38px;
      line-height: 45px;
    }
  }

  h2[data-header='two'] {
    font-family: 'Vollkorn', sans-serif;
    font-weight: 400;
    font-size: 44px;
    line-height: 46px;
    margin: 0 0 30px;
    text-align: center;

    span {
      font-weight: 700;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  h3[data-header='three'] {
    display: flex;
    align-items: flex-start;
    color: $black;
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 22px;

    span {
      height: 22px;
      line-height: 16px;
      margin-left: 8px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 26px;
      margin: 0 0 15px;
    }
  }

  p[data-header='desc'] {
    color: rgba($black, 0.8);
    font-size: $font-size-large;
    line-height: 26px;
    margin-bottom: 20px;

    strong {
      font-weight: 600;
    }
  }

  p[data-header='desc-large'] {
    color: rgba($black, 0.8);
    font-size: $font-size-extra-large;
    max-width: 560px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 40px;

    @media (max-width: $breakpoint-mobile) {
      font-size: $font-size-large;
      line-height: 26px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    width: 100%;
    margin-bottom: 100px;
  }
}
