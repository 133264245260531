@import './variables.scss';

.syncingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba($black, 0.25);

  .inner {
    @include elevation-2();
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;

    .title {
      margin-left: 8px;
    }
  }
}
