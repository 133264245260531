@import './variables.scss';

.help {
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 0.5;
  border-radius: $border-radius;
  transition: opacity $anim-duration $anim-easing,
    background-color $anim-duration $anim-easing;

  &:hover {
    opacity: 0.9;
    background-color: $border-color;
  }

  > span {
    display: flex;
    padding: 4px;
  }
}
