@import './variables.scss';

.chooseGoals {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  width: 100%;

  .loading {
    color: $font-color-muted;
  }

  .goal {
    padding: 5px;
    flex: 1 1 50%;

    &.isSelected .inner {
      background-color: rgba($blue2, 0.1);
      box-shadow: inset 0 0 0 1px rgba($blue2, 0.1);

      &:hover {
        background-color: rgba($blue2, 0.1);
      }
    }

    .inner {
      @include elevation-2();
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 8px;
      height: 100%;
      border-radius: $border-radius;
      text-align: center;
      line-height: 18px;
      user-select: none;
      transition: background-color $anim-duration $anim-easing,
        box-shadow $anim-duration $anim-easing;

      &:hover {
        background-color: $gray3;
      }
    }
  }
}
