@import '../variables.scss';

.featuresDeepDive {
  border-radius: 20px 20px 0 0;
  padding: 0 25px 70px 25px;
  margin-bottom: 0;

  .inner {
    max-width: 860px;
    width: 100%;
  }

  h2 {
    color: $black;
    margin-bottom: 16px;

    span {
      display: block;

      @media (max-width: $breakpoint-mobile) {
        display: inline;
      }
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      margin: 0 !important;
    }

    .divider {
      height: 1px;
      background-color: $border-color;
      width: 230px;
      margin-top: 45px;
    }
  }

  .list {
    padding-top: 55px;

    .item {
      display: flex;
      align-items: center;
      padding-bottom: 80px;

      &:last-child {
        padding-bottom: 0;
      }

      &.imgLeft > .image {
        margin-right: 60px;
      }

      &.imgRight > .image {
        margin-left: 60px;
      }

      &.imgBtm {
        flex-direction: column;
        align-items: center;

        .info {
          text-align: center;
          max-width: 700px;

          h3 {
            flex: 1 1 auto;
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
          }

          p {
            max-width: 500px;
            margin: 0 auto;
          }
        }

        > * {
          width: 100%;
        }
      }

      .image {
        flex: none;
        width: 400px;
        height: 250px;
        background: $white;
        box-shadow: 0 1px 0 rgba($black, 0.1), 0 5px 16px rgba($black, 0.25);
        background-size: 100% auto;
        border-radius: 6px;
        background-repeat: no-repeat;

        &[data-which='daily-reports'] {
          background-image: url(../../images/mktg-daily-reports.png);
          background-size: 100% auto;
        }

        &[data-which='wellness-blocks'] {
          background-image: url(../../images/mktg-wellness-blocks.png);
        }

        &.evaluationsCard {
          display: flex;
          padding: 20px 0 0 20px;
          align-items: flex-start;

          > div {
            flex: 1 1 auto;
          }

          .list {
            @include elevation-2();
            display: flex;
            flex-direction: column;
            flex: none;
            width: 60px;
            margin-left: 30px;
            height: 100%;
            padding: 20px 0 20px 20px;

            .listItem {
              flex: none;
              width: 100%;
              height: 8px;
              background-color: $gray2;
              margin-bottom: 20px;
              border-top-left-radius: $border-radius;
              border-bottom-left-radius: $border-radius;
            }
          }
        }
      }

      .info {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        padding-bottom: 60px;

        &.imgLeft {
          flex-direction: column-reverse;
        }

        > .image {
          min-width: 380px;
          align-self: flex-start;
          margin: 30px 0 0 0 !important;
          width: 100%;
          background-size: cover !important;
        }

        &.imgBtm > .image {
          margin: 30px 0 0 0 !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .featuresDeepDive {
    padding-bottom: 0;

    .inner {
      .list {
        .item {
          .info {
            text-align: center;
          }
        }
      }
    }
  }
}
