@import './variables.scss';

.card {
  @include elevation-2();
  border-radius: $border-radius;
  position: relative;

  > .arrow {
    position: absolute;
    right: -39px;
    top: 68px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    &:before {
      content: '';
      display: block;
      height: 4px;
      width: 128px;
      position: absolute;
      top: 14px;
      right: 25px;
      border-radius: 2px;
      background-color: #8a94a4;
    }

    > svg {
      width: 32px;
      height: 32px;
    }
  }
}

.summary {
  padding: 17px 20px;

  .row {
    display: flex;
    align-items: center;
    line-height: 18px;
    margin-bottom: 6px;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      margin-right: 6px;
      flex: none;
      position: relative;
      z-index: 1;
    }

    &.highlight {
      margin-bottom: 14px;

      > label {
        position: relative;

        &:after {
          content: '';
          display: block;
          border-radius: $border-radius;
          background-color: $yellow1;
          height: 2px;
          position: absolute;
          right: 0;
          left: 0;
          bottom: -4px;
          z-index: 0;
        }
      }
    }

    .teamName {
      font-weight: 600;
      text-transform: capitalize;
      margin: 0;
    }

    .value {
      flex: none;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 800;
      height: 20px;
      padding: 0 5px 0;
      border-radius: $border-radius;
      box-shadow: 0 0 0 1px $border-color;

      > span {
        line-height: 20px;
      }

      .oneHundred {
        font-size: 12px;
        flex: 0 0 auto;
        color: $font-color-muted;
        font-weight: 600;
        margin-left: 3px;
      }
    }
  }
}
