@import '../variables.scss';

.emailOrganizer {
  height: 350px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid rgba($gray1, 0.3);

  $overflowGap: 30px;
  $headerHeight: 64px;

  .phone {
    @include elevation-2();
    display: flex;
    width: 350px;
    height: calc(100% + #{$overflowGap});
    border-radius: 30px;
    padding: 10px 10px 0;
  }

  .screen {
    flex: 1 1 auto;
    background: rgba($gray1, 0.08);
    border-radius: 20px;
    box-shadow: inset 0 1px 0 rgba($gray0, 0.1), inset 0 0 3px rgba($gray0, 0.1);
  }

  .header {
    display: flex;
    border-bottom: 1px solid rgba($gray1, 0.3);
    padding: 15px 15px 12px;
    height: $headerHeight;

    .logo {
      width: 36px;
      height: 36px;
      background: $white;
      border-radius: 50%;
      margin-right: 8px;
      background-image: url(../../images/logo-nav.svg);
      background-size: auto 22px;
      background-position: 7px center;
      background-repeat: no-repeat;
      border: 1px solid rgba($gray1, 0.3);
    }

    .contact {
      .name {
        font-weight: 600;
        margin-bottom: 2px;
      }

      .to {
        color: $font-color-muted;
      }
    }

    .time {
      margin-left: auto;
      color: $font-color-muted;
    }
  }

  .email {
    height: calc(100% - #{$overflowGap} - #{$headerHeight});
    overflow: hidden;
    position: relative;

    .scroll {
      position: absolute;
      top: 0;
      width: 100%;
      padding: 15px 20px;
      transition: transform $anim-duration * 8 $anim-easing;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba($gray1, 0.3);
    }

    .body {
      padding-top: 15px;

      p {
        font-size: $font-size-medium;
        line-height: 20px;
        color: $font-color-muted;
      }

      h4 {
        font-size: $font-size-medium;
        line-height: 20px;
        color: $font-color;
        border-top: 1px solid rgba($gray1, 0.3);
        margin: 0 0 12px;
        padding-top: 15px;
        font-weight: 600;
      }

      strong {
        font-weight: 600;
      }

      .negativeDetail {
        display: flex;
        border: 1px solid rgba($gray1, 0.3);
        background-color: rgba($white, 0.2);
        padding: 10px 10px 7px;
        border-radius: $border-radius;
        margin-bottom: 10px;
        line-height: 19px;

        .text {
          margin-left: 5px;
        }

        .name {
          font-weight: 500;
        }

        .reason {
          color: $font-color-muted;
        }
      }
    }
  }

  &.isVisible {
    .email {
      .scroll {
        transform: translateY(-195px);
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .emailOrganizer {
    padding: 20px 20px 0;
    margin-top: -20px;
  }
}
