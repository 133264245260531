@import './variables.scss';

.compliNonMeetingsOuterModal {
  align-items: center !important;
  justify-content: center !important;
}

.compliNonMeetingsModal {
  display: flex;
  flex-direction: column;
  left: auto !important;
  top: auto !important;
  width: 100% !important;
  max-width: 750px !important;
  min-width: 500px !important;
  height: auto !important;
  min-height: 10vh !important;
  max-height: 80vh !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.compliNonMeetings {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex: none;
    padding: 25px 30px 20px;
    border-bottom: 1px solid $border-color;
  }

  .list {
    display: block;
    overflow: auto;

    .item {
      display: flex;
      padding: 15px 20px 15px 30px;
      border-bottom: 1px solid rgba($border-color, 0.6);

      .info {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: $font-size-medium;
        font-weight: 700;
        margin-bottom: 6px;
      }

      .score {
        font-size: $font-size;
        font-weight: 700;
        margin-right: 20px;
        color: $font-color-muted;

        span {
          color: $font-color;
          font-size: $font-size-large;
        }
      }

      .cost {
        margin-right: 10px;

        > div {
          font-size: $font-size-large;

          > span {
            font-size: $font-size-medium;
          }
        }
      }

      .deets {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-left: 30px;
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .compliNonMeetings {
    .list {
      .item {
        flex-direction: column;

        .deets {
          padding: 0;
          margin: 10px 0 0 0;
        }
      }
    }
  }
}
