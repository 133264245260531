@import './variables.scss';
@import './review.module.scss';

.leaderboard {
  @include review-styles-section();
  max-width: 225px;
  margin-left: 30px;
  flex: none;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-mobile) {
    &:not(.isForMarketing) {
      margin-left: 0;
      max-width: none;
      padding-top: 30px;
    }
  }

  .card {
    flex-direction: column;
    padding: 20px;
    max-height: 400px;
    overflow: auto;
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    color: $font-color-muted;
    text-transform: uppercase;
    margin-bottom: 15px;

    > div {
      white-space: nowrap;
    }

    .score {
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .organizer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    min-width: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .image {
      width: 26px;
      height: 26px;
      background-color: $gray1;
      margin-right: 8px;
      flex: none;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .legendItem {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: red;
        border: 2px solid $white;
        top: -6px;
        left: -6px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      min-width: 0;
      padding-right: 8px;

      .name {
        @include text-overflow-ellipsis();
        font-weight: 600;
        font-size: $font-size-small;
        margin-bottom: 2px;
      }

      .nudges {
        @include text-overflow-ellipsis();
        color: $font-color-muted;
        font-size: $font-size-small;
      }
    }

    .avgScore {
      margin-left: auto;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      flex: none;
      align-self: start;

      > div > div {
        white-space: nowrap;
      }
    }
  }
}
