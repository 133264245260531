@import './variables.scss';

.wellnessBlocksTout {
  padding-top: 30px;

  h5 {
    display: flex;
    align-items: center;
    color: $blue1 !important;
    margin-bottom: 15px !important;

    .addWellnessBlock {
      margin-left: auto;
      flex: none;
      text-transform: none;
      font-weight: 400;
      color: $font-color-muted;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .card {
    @include elevation-2();
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: $border-radius;
    overflow: hidden;
  }
}

.block {
  display: flex;
  padding: 12px 15px;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  .icon {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
  }

  .iconWrapper {
    border-radius: $border-radius;
    padding: 8px;

    &.focus {
      background-color: lighten($blue1, 40%);
    }

    &.life {
      background-color: lighten($purple2, 60%);
    }
  }

  .info {
    flex-direction: column;
  }

  .label {
    display: flex;
    align-items: center;
    font-size: $font-size;
    font-weight: 600;
    margin-bottom: 0;
  }

  .ruleAndTime {
    font-size: $font-size-small;
    color: $font-color-muted;
    min-height: 20px;
    display: flex;
    align-items: center;
  }

  .edit {
    flex: none;
    margin-left: auto;
  }
}

.missingWellnessBlocks {
  position: relative;

  .descText {
    padding-bottom: 15px;
  }
}

.wellnessBlocksModal {
  display: flex;
  flex-direction: column;

  .disabledCallout {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    line-height: 21px;
    padding: 4px 4px 4px 10px;

    .makeWeeklyBtn {
      margin-left: auto;
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;

    > h5 {
      margin-bottom: 15px;
    }

    .ruleAndTime {
      color: $font-color-muted;
      margin-bottom: 10px;
    }

    .timePicker {
      display: flex;
      align-items: center;

      > .option {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: $border-color;
      margin: 15px 0;
    }

    .deleteButton {
      margin-top: 5px;
    }
  }
}

.wbSuggestions {
  display: flex;
  margin-top: 10px;

  .label {
    color: $font-color-muted;
    flex: none;
    height: 30px;
    line-height: 31px;
    margin-right: 10px;
  }

  .suggs {
    display: flex;
    flex-wrap: wrap;

    .suggestion {
      @include click-elevation();
      height: 30px;
      display: flex;
      align-items: center;
      border-radius: 15px;
      line-height: 31px;
      padding: 0 10px;
      margin: 0 5px 5px 0;
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
}
