@import './variables.scss';

@mixin review-styles-section {
  padding-top: 50px;

  &:last-child {
    border-bottom: none;
  }

  > h5 {
    color: $blue1 !important;
    margin-bottom: 10px !important;
  }

  > .description {
    font-size: $font-size-small;
    line-height: 16px;
    color: $font-color-muted;
    margin-bottom: 20px;

    .example {
      text-decoration: underline;
    }
  }

  .card {
    @include elevation-2();
    display: flex;
    color: $black;
    background: $white;
    border-radius: $border-radius;
    cursor: default;

    &.noClick {
      pointer-events: none;
    }
  }

  .module {
    flex: 1 1 auto;
  }
}

.reviewView {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  :global(.rdrDefinedRangesWrapper) {
    width: 115px;
  }

  .transparentEmptyState {
    background-color: transparent;
  }

  .subNav {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 76px;
    padding: 0 20px;
    border-bottom: 1px solid $border-color;

    .viewToggles {
      display: flex;
      margin-right: auto;

      > div {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;
      margin-left: auto;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .statusFilter {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .label {
        color: $font-color-muted;
        margin-right: 10px;
      }

      .options {
        display: flex;

        .divider {
          content: '';
          display: block;
          width: 1px;
          background-color: $border-color;
          height: 30px;
          margin: 0 5px;
        }
      }
    }

    .dateRangePicker {
      display: flex;
      align-items: center;
    }

    .dateRangeText {
      display: flex;

      .labelText {
        margin-right: 10px;
        font-weight: 400;
        color: $font-color-muted;
      }

      .arrows {
        margin: 0 6px;
      }
    }

    .export {
      margin-left: 10px;
    }

    :global(.rdrDays) {
      color: red;
    }
  }

  .inner {
    flex: 1 1 auto;
    position: relative;
    overflow: auto;

    .inside {
      flex: 1 1 auto;
      max-width: 1060px;
      width: 100%;
      margin: 0 auto;

      .shh {
        display: block;
        padding: 0 50px 50px;
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-medium) {
  .reviewView {
    .subNav {
      flex-direction: column;
      height: auto;
      padding: 15px 0;

      .viewToggles {
        margin: 0 0 15px 0;
      }

      .filters {
        margin: 0;

        .statusFilter {
          margin-right: 20px;
        }
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .reviewView {
    .subNav {
      flex-direction: column;
      height: auto;
      padding: 15px 0;

      .viewToggles {
        margin: 0 0 6px 0;
      }

      .labelText {
        margin-right: 8px;
      }
    }

    .inner {
      .inside {
        .shh {
          padding: 30px;
        }
      }
    }
  }
}
