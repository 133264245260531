@import '../variables.scss';

.enter {
  opacity: 0;

  > div:first-child {
    opacity: 0;
    transform: translateX(160px);
  }
}

.enterActive {
  opacity: 1;

  > div:first-child {
    opacity: 1;
    transform: translateX(0px);
  }
}

.enterDone {
  opacity: 1;

  > div:first-child {
    opacity: 1;
    transform: translateX(0);
  }
}

.exit {
  opacity: 1;

  > div:first-child {
    opacity: 1;
    transform: translateX(0);
  }
}

.exitActive {
  opacity: 0;

  > div:first-child {
    opacity: 0;
    transform: translateX(0);
  }
}

.exitDone {
  opacity: 0;

  > div:first-child {
    opacity: 0;
    transform: translateX(0);
  }
}
