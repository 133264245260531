@import './variables.scss';
@import './review.module.scss';

.detailedStats {
  display: flex;
  padding-top: 50px;

  .categoryIntentions {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    > .section {
      flex: none;
    }

    > .section:first-child {
      margin-bottom: 30px;
    }
  }

  .section {
    @include review-styles-section();
    padding: 0;
    flex: 1 1 0;
    min-width: 0;

    @mixin column-widths() {
      &.type {
        flex-grow: 2.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > label {
          font-weight: 400;
          color: $font-color-muted;
        }
      }

      &.count {
        flex-grow: 0.7;
      }

      &.goodBad {
        display: flex;
        align-items: center;
        flex-grow: 1.8;

        .good {
          flex: none;
          white-space: nowrap;
          color: $green2;
        }

        .divider {
          flex: none;
          background-color: $border-color;
          width: 1px;
          height: 16px;
          display: block;
          margin: 0 4px;
        }

        .bad {
          flex: none;
          white-space: nowrap;
          color: $intent-danger;
        }

        > span > span {
          font-size: 10px;
        }
      }

      &.nudges {
        flex-grow: 1;
      }

      &.cost {
        flex-grow: 1.5;

        .dollarSign {
          font-weight: 400;
        }
      }

      &.hours {
        flex-grow: 1.5;
      }
    }

    .card {
      flex-direction: column;
      margin-bottom: 10px;
      min-height: 75px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      .empty {
        flex: 1 1 auto;
        display: none;
        align-items: center;
        justify-content: center;
        color: $font-color-muted;
        padding: 10px 0;
      }

      .header {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 1px solid $border-color;
        user-select: none;

        > span {
          @include column-widths();
          flex: 1 0 0;
          display: flex;
          align-items: center;
          padding: 10px 0 8px 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $font-color-muted;
        }

        .sortIcon {
          opacity: 0.6;
          margin-top: -1px;

          > svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      .rows {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 393px;
        overflow: auto;
        min-height: 0;

        &:empty ~ .empty {
          display: flex;
        }

        .row {
          flex: none;
          min-height: 0;
          min-width: 0;
          margin: 1px 0;
          display: flex;
          align-items: center;
          padding: 14px 0 11px;
          position: relative;
          border-bottom: 1px solid $border-color;

          &:last-child {
            border-bottom: none;
          }

          > span {
            @include column-widths();
            flex: 1 1 0;
            padding: 0 0 0 15px;

            &.type {
              font-weight: 600;
            }
          }

          .inviteBtn {
            position: absolute;
            right: 8px;
            top: 9px;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;

    .categoryIntentions {
      margin-right: 0;
      margin-bottom: 30px;
    }

    .section {
      margin: 0;
    }
  }
}
