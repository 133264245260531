@import './variables.scss';

.accountView {
  overflow: auto;
  width: 100%;
  padding: 30px;

  .inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  hr {
    margin: 20px 0 25px;
  }

  .mainContent {
    display: flex;
    align-items: flex-start;
  }

  .views {
    flex: 1 1 auto;
  }

  .columns {
    display: flex;
    padding-top: 15px;
  }

  .column {
    flex: 1 1 0;

    &:first-child {
      margin-right: 30px;
    }

    &:last-child {
      flex-grow: 1.8;
    }
  }

  .section {
    @include elevation-2();
    border-radius: $border-radius;
    margin-bottom: 30px;
    padding: 25px;

    h5 {
      margin-bottom: 15px;
      color: $blue1;
    }

    .desc {
      color: $font-color-muted;
      font-size: $font-size-small;
      margin-bottom: 14px;
      line-height: 14px;
    }

    .example {
      text-decoration: underline;
    }
  }

  .signOutButton {
    margin-bottom: 10px;
  }

  .calList {
    margin-bottom: 15px;
  }

  .planInfo {
    .info {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $white;
        position: relative;
        z-index: 1;
      }
    }

    .pic {
      width: 200px;
      height: 193px;
      margin: 0 auto;
    }

    .planLabel {
      flex: none;
      background-color: rgba($blue1, 0.15);
      color: $blue1;
      text-transform: uppercase;
      font-size: $font-size-small;
      font-weight: 700;
      text-align: center;
      padding: 0 10px;
      border-radius: 9px;
      margin-bottom: 8px;
      height: 18px;
      line-height: 19px;
    }

    .name {
      font-size: $font-size-extra-large;
      font-weight: 700;
      text-align: center;
      margin: 8px 0 5px;
    }

    .description {
      text-align: center;
      color: $font-color-muted;
      line-height: 18px;
      padding: 0 5px;
    }

    .changePlanBtn {
      padding-top: 15px;
    }

    .manageTeamBtn {
      margin-top: 10px;
    }
  }

  .adminArea {
    margin-top: 30px;

    > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.deleteInput {
  margin-bottom: 15px;
}

.slackDndDetails {
  display: flex;
  align-items: center;
  width: 100%;

  .slackAddToBtn {
    display: flex;
    align-items: center;

    .logo {
      display: block;
      background-image: url(../images/slack-logo.svg);
      background-size: 100% auto;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    .text {
      line-height: 19px;
    }
  }

  .status {
    margin-left: auto;
    flex: none;
    padding-left: 10px;

    .added {
      display: flex;
      align-items: center;

      .manageBtn {
        margin-left: 8px;
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .accountView {
    .columns {
      flex-direction: column;

      .column {
        margin: 0;
      }
    }
  }
}
