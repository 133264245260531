@import './variables.scss';

.eventWrapper {
  display: flex;

  .eventInfo {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    width: 100%;
    pointer-events: none;
  }

  &.highQuality {
    color: darken($intent-success, 45%);

    .eventBkg {
      background: lighten($intent-success, 30%);
    }

    &:hover .eventBkg {
      background: lighten($intent-success, 22%);
    }
  }

  &.mediumHighQuality {
    color: darken($intent-caution, 45%);

    .eventBkg {
      background: lighten($intent-caution, 28%);
    }

    &:hover .eventBkg {
      background: lighten($intent-caution, 18%);
    }
  }

  &.mediumLowQuality {
    color: darken($intent-warning, 30%);

    .eventBkg {
      background: lighten($intent-warning, 32%);
    }

    &:hover .eventBkg {
      background: lighten($intent-warning, 25%);
    }
  }

  &.lowQuality {
    color: darken($red2, 10%);

    .eventBkg {
      background-color: lighten($intent-danger, 33%);
    }

    &:hover .eventBkg {
      background-color: lighten($intent-danger, 28%);
    }
  }

  &.focusBlock,
  &.lifeBlock {
    color: darken($blue1, 25%);

    .eventTitle {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding-top: 2px;

      > div {
        flex: none;
        opacity: 0.6;
        margin: -2px 6px 0 0;
      }
    }

    .eventBkg {
      background: repeating-linear-gradient(
        45deg,
        lighten($blue1, 38%),
        lighten($blue1, 38%) 10px,
        lighten($blue1, 35%) 10px,
        lighten($blue1, 35%) 20px
      );
    }

    &:hover .eventBkg {
      background: repeating-linear-gradient(
        45deg,
        lighten($blue1, 33%),
        lighten($blue1, 33%) 10px,
        lighten($blue1, 30%) 10px,
        lighten($blue1, 30%) 20px
      );
    }
  }

  &.lifeBlock {
    color: darken($purple2, 25%);

    .eventBkg {
      background: repeating-linear-gradient(
        45deg,
        lighten($purple2, 55%),
        lighten($purple2, 55%) 10px,
        lighten($purple2, 52%) 10px,
        lighten($purple2, 52%) 20px
      );
    }

    &:hover .eventBkg {
      background: repeating-linear-gradient(
        45deg,
        lighten($purple2, 50%),
        lighten($purple2, 50%) 10px,
        lighten($purple2, 47%) 10px,
        lighten($purple2, 47%) 20px
      );
    }
  }

  &.maybeFocusBlock {
    color: darken($blue1, 25%);

    .eventBkg {
      background: lighten($blue1, 40%);
    }

    &:hover .eventBkg {
      background: lighten($blue1, 38%);
    }
  }

  &.maybeLifeBlock {
    color: darken($purple2, 25%);

    .eventBkg {
      background: lighten($purple2, 60%);
    }

    &:hover .eventBkg {
      background: lighten($purple2, 58%);
    }
  }

  &.dailyReport {
    height: 28px;

    .eventTitle {
      font-weight: 700;
      font-size: 10px;
      opacity: 0.85;
      padding: 4px 0 0 5px;
    }

    .eventBkg {
      opacity: 1;
    }
  }

  .eventTitle {
    font-size: $font-size-small;
    line-height: 14px;
    font-weight: 600;
  }

  .eventDesc {
    margin: 4px 0 7px;
    font-size: 10px;
    line-height: 14px;
  }

  .eventScoreWrapper {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba($white, 0.9);
    border-radius: $border-radius;
  }

  .eventScore {
    display: flex;
  }

  .eventBkg {
    border-radius: $border-radius;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: rgba($gray1, 0.2);
    opacity: 0.8;
    cursor: pointer;
    transition: opacity $anim-duration $anim-easing,
      background $anim-duration $anim-easing;

    &:hover {
      background: rgba($gray1, 0.3);
    }
  }
}
