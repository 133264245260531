@import './variables.scss';

.dateDetails {
  display: flex;

  time,
  span {
    flex: none;
    color: $font-color-muted;
  }

  span {
    padding: 0 6px;
  }

  .timeRange {
    display: flex;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $gray2;
  }
}
