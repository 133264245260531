@import './variables.scss';

.popoverWrapper {
  position: relative;
}

.popoverPortal {
  position: fixed;
  z-index: 9999998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.popover {
  @include elevation-2();
  position: fixed;
  z-index: 9999998;
  top: 0;
  left: 0;
  display: flex;
  background: $white;
  border-radius: $border-radius;
  min-width: 100px;
  transition: opacity $anim-duration $anim-easing,
    visibility $anim-duration $anim-easing;
}

/// MEETING MODAL STYLES
.editMeeting {
  display: flex;
  flex-direction: column;
  width: 350px;

  &.editIntention,
  &.editCategory {
    width: 220px;
  }

  &.editPreWork {
    width: 450px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .emptyItems {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      text-align: center;
      color: $font-color-muted;
    }

    .input {
      display: flex;
      flex: 1 1 auto;

      .description {
        flex: 1 1 auto;
      }

      .duration {
        width: 70px;
        flex: 0 1 auto;

        input {
          padding-right: 5px;
        }
      }
    }
  }

  .textArea {
    border: none;
    resize: none;
    padding: 10px;
    font-size: $font-size-small;
    outline: none;
    box-shadow: inset 0 0 0 1px rgba($gray1, 0.5);
    border-radius: $border-radius;
  }
}

.agendaItemInput {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  .dragHandle {
    padding: 0;
    margin-left: 3px;
    cursor: move;
  }
}

.objectiveItemInput {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  .dragHandle {
    padding: 0;
    margin-left: 3px;
    cursor: move;
  }
}

.preWorkItemInput {
  display: flex;
  margin-bottom: 5px;

  .inputs {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .input {
      &:first-child {
        margin-bottom: -1px;
        border-radius: $border-radius $border-radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .dragHandle {
    padding: 0;
    margin-left: 3px;
    cursor: move;
  }
}

.scroll {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.actionButtons {
  flex: none;
  display: flex;
  padding: 10px;
  border-top: 1px solid $border-color;

  > div,
  > span {
    flex: 1 1 auto;
    display: flex;
    min-width: 60px;

    &:first-child {
      margin-right: 5px;
    }
  }
}

// DATE RANGE PICKER
.dateRangePickerPopover {
  width: 100%;
}

//// TIME RANGE PICKER
.timeRangePicker {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  width: 140px;
  min-width: 140px;
  overflow: auto;
  padding: 5px;

  .option {
    flex: 1 0 auto;
    display: flex;
  }
}

.scroll {
  > .title {
    font-weight: 600;
    margin-bottom: 3px;
  }

  > .description {
    color: $font-color-muted;
    font-size: $font-size-small;
    border-bottom: 1px solid $border-color;
    line-height: 16px;
    padding: 0 10px 10px;
    margin-bottom: 10px;
    text-align: center;

    &.leftAligned {
      text-align: left;
      padding: 0 0 8px;
    }
  }
}

// PLAN ACTION
.editingPlan,
.editingPlanSetting {
  display: flex;
  flex-direction: column;

  &.editingPlanSetting {
    padding: 0 5px 5px 5px;
    width: 250px;
  }
}

.editPlanAction {
  width: 330px;

  .options {
    .tagHeader {
      display: flex;
      align-items: center;
      margin: 10px 0 5px 10px;

      .viewExample {
        margin-left: auto;
        text-decoration: underline;
        font-size: $font-size-small;
        color: $font-color-muted;
        cursor: pointer;
        transition: background-color $anim-duration $anim-easing;

        &:hover {
          background-color: rgba($gray2, 0.3);
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: $border-color;
      margin: 10px 0;
    }

    .option {
      display: flex;
      flex-direction: column;
      border-radius: $border-radius;
      padding: 10px;
      user-select: none;
      transition: background-color $anim-duration $anim-easing;

      &:hover {
        background-color: rgba($gray2, 0.25);
      }

      &:active {
        background-color: rgba($gray2, 0.6);
      }

      &.selected {
        background: rgba($blue1, 0.08);
      }

      &.selected:hover {
        background: rgba($blue1, 0.12);
      }

      .content {
        display: flex;
        align-items: center;
        width: 100%;

        .text {
          flex: none;
          font-weight: 600;
          line-height: 22px;
          height: 20px;
          margin-right: 6px;
        }

        .subOptions {
          display: flex;
          align-items: center;
          margin-left: auto;

          .subOption {
            pointer-events: all;
          }

          > div:first-child {
            margin-right: 5px;
          }
        }
      }

      .description {
        display: flex;
        align-items: center;
        text-align: left;
        color: $font-color-muted;
        font-size: $font-size-small;
        width: 100%;
        padding: 0 0 0 32px;
        margin-top: 4px;
        line-height: 16px;
      }
    }
  }

  .callout {
    margin-top: 10px;
  }
}

.editDomainEnforcement,
.editOrganizerEnforcement,
.editDeadline {
  display: flex;
  flex-direction: column;
  width: 250px;

  .segmentedControl {
    display: flex;
    margin-bottom: 5px;

    > div {
      flex: 1 1 0;
    }
  }
}

.editOrganizerEnforcement {
  .tagsInput :global(.react-tagsinput-input) {
    width: 190px;
  }
}

.editSalary {
  width: 250px;

  .salaryInput {
    display: flex;
    align-items: center;
    position: relative;

    .dollarSign {
      font-size: $font-size-large;
      position: absolute;
      left: 10px;
      top: 11px;
      font-weight: 400;
      color: $font-color-muted;
      pointer-events: none;
    }

    .salary {
      flex: 1 1 auto;

      > input {
        padding-left: 28px;
        font-size: $font-size-large;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .editMeeting {
    width: calc(100vw - 70px) !important;
  }

  .dateRangePickerPopover {
    .dateRangePicker > div {
      min-width: calc(100vw - 30px);
    }

    :global(.rdrDefinedRangesWrapper) {
      display: none;
    }
  }
}
