@import '../variables.scss';

.howExampleMeeting {
  position: relative;
  width: 700px;
  height: 550px;
  transform: scale(0.8);
  margin-left: -50px;

  @media (max-width: $breakpoint-mobile) {
    transform: scale(0.6);
    margin-left: -120px;
    margin-top: -105px;
    margin-bottom: -107px;
  }

  .meeting {
    @include elevation-2();
    display: flex;
    flex-direction: column;
    z-index: 1;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    pointer-events: none;

    &:not(.stack) {
      opacity: 1;
      transform: rotate(-6deg);
      transition: opacity $anim-duration * 2 $anim-easing,
        transform $anim-duration * 2 $anim-easing;
    }

    &.stack {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: rotate(-6deg);
      z-index: 0;
    }
  }

  &.isVisible {
    .meeting:not(.stack) {
      transform: rotate(0);
    }
  }
}
