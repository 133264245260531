@import './variables.scss';

.choosePlan {
  @include bkg-gradient();
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 30px 30px;
  overflow: auto;

  &.isForMarketing {
    background: none;
    overflow: visible !important;
    padding: 0;

    .header:first-of-type {
      display: none;
    }
  }

  .header {
    flex: none;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;

    span {
      font-family: 'Vollkorn', sans-serif;
      font-size: $font-size-large;
      color: $font-color-muted;
      height: 22px;
      line-height: 26px;
      padding: 0 10px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: $border-color;
      flex: 1 1 auto;
    }
  }

  .plans {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
  }

  .plan {
    @include elevation-2();
    background-color: rgba($white, 0.5);
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: $border-radius;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      border-left: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      padding: 25px 20px 20px 25px;
      border-bottom: 1px solid $border-color;

      .info {
        display: flex;
        flex-direction: column;
      }

      .name {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 800;
        height: 40px;

        > span {
          margin-right: 5px;
        }
      }

      .description {
        line-height: 18px;
      }
    }

    .pricingDesc {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 25px;
      margin-bottom: 12px;
      min-height: 80px;

      .details {
        display: flex;
        flex-direction: column;

        .price {
          display: flex;
          align-items: baseline;
          font-size: 42px;
          font-weight: 800;
          margin-bottom: 6px;

          span {
            font-size: 22px;
            padding-right: 8px;
            height: 22px;
            line-height: 22px;
          }

          .num {
            flex: none;
            font-size: 52px;
            margin-left: -4px;
            height: 36px;
            line-height: 36px;
          }

          label {
            font-size: 18px;
            padding-left: 4px;
          }

          .misc {
            color: $font-color-muted;
            font-weight: 800;
            font-size: 10px;
            text-transform: uppercase;
            align-self: center;
            padding-left: 8px;
          }
        }

        .sub {
          color: $font-color-muted;
          margin-top: 4px;
        }
      }

      .pic {
        flex: 0 0 auto;
        width: 85px;
        height: 82px;
        margin-left: auto;
      }
    }
  }

  .listDetails {
    display: flex;

    .listGroup {
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      .groupHeader {
        color: $font-color-muted;
        font-weight: 800;
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .listItem {
        display: flex;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        > span {
          padding: 2px 10px 0 7px;
          line-height: 16px;
        }

        .help {
          align-self: flex-start;
          margin-left: auto;
          pointer-events: all !important;
          opacity: 0.5;
          border-radius: $border-radius;
          transition: opacity $anim-duration $anim-easing,
            background-color $anim-duration $anim-easing;

          &:hover {
            opacity: 0.9;
            background-color: $border-color;
          }

          * {
            pointer-events: all !important;
          }

          > span {
            display: flex;
            padding: 4px;
          }
        }
      }
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .choosePlan {
    overflow: auto !important;
    padding: 15px;

    .plans {
      flex: none;
      flex-direction: column;

      .plan {
        flex: none;
        margin-right: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .listDetails {
      flex-direction: column;
      padding: 0 10px;

      .listGroup {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
}
