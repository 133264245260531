@import './variables.scss';

.roiCalculator {
  display: flex;
  justify-content: center;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  width: 100%;
  padding: 60px 0;
  background-color: rgba(242, 247, 255, 0.4);
  position: relative;

  .anchorOffset {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: transparent;
    pointer-events: none;
    top: -90px;
  }

  &.isInApp {
    align-items: center;
    background-color: transparent;
    border: none;
    height: 100%;
    margin: 0;
  }

  .inner {
    display: flex;
    flex-direction: column;
    max-width: 680px;
  }

  .top {
    margin-bottom: 30px;

    .title {
      font-family: 'Vollkorn', sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 46px;
      margin: 0 0 16px;
      text-align: center;

      span {
        font-weight: 700;
      }
    }

    p {
      color: rgba($black, 0.8);
      font-size: $font-size-medium;
      line-height: 22px;
      margin-bottom: 0;
      text-align: center;

      span {
        font-weight: 600;
      }
    }
  }

  .inputs {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;

    .input {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      &.num {
        input {
          padding: 0 5px 0 20px;
        }
      }

      input {
        height: 60px;
        width: 150px;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        padding: 0 5px;
      }

      .title {
        text-align: center;
        padding-top: 10px;
      }
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: $font-size-extra-large;
    line-height: 32px;

    span {
      font-weight: 400;
    }

    label {
      font-weight: 700;

      > span {
        font-weight: 700;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .roiCalculator {
    .inner {
      padding: 0 20px;
    }

    .inputs {
      .input {
        margin-right: 10px;
        width: 120px;

        &.num {
          width: 80px;

          input {
            padding: 0 5px;
            width: 80px;
          }
        }

        input {
          height: 50px;
          width: 120px;
          font-size: 26px;
        }
      }
    }
  }
}
