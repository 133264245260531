@import '../variables.scss';

.subpageView {
  @include bkg-gradient();
  display: flex;
  flex-direction: column;
  width: 100%;
}

.miscPage {
  @include bkg-gradient();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 80px 40px;
  width: 100%;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 560px;
  }

  .emoji {
    font-size: 60px;
    line-height: 80px;
  }

  .reasonsWhy {
    @include elevation-2();
    border-radius: $border-radius;
    padding: 18px 20px 15px 40px;
    margin: 0 0 20px;
    line-height: 22px;
  }

  h1 {
    margin: 15px 0 20px !important;
    text-align: center;

    span {
      font-weight: 800;
    }
  }

  p {
    text-align: center;
    line-height: 22px;
    margin-bottom: 15px !important;
  }

  h5 {
    margin: 20px 0 !important;

    &.blue {
      color: $blue1;
      margin-top: 10px !important;
    }

    &.centered {
      flex: 1 1 auto;
      display: flex;
      text-align: center;
      align-items: center;
      width: 100%;
      margin-bottom: 0 !important;

      &::before,
      &::after {
        content: '';
        height: 1px;
        background-color: $border-color;
        flex: 1 1 auto;
      }

      &::before {
        margin-right: 10px;
      }

      &::after {
        margin-left: 10px;
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 30px 40px;
    border-radius: $border-radius;
    margin: 30px 0;
    background-color: rgba($orange1, 0.07);
    box-shadow: inset 0 0 0 1px rgba($orange1, 0.3);

    h5 {
      text-align: center;
    }

    p {
      text-align: center;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      > div {
        margin: 5px;
      }
    }
  }

  .attendeeNotes {
    width: 360px;
  }
}

.runningText {
  @include running-text();
}

.pricingPage.subpageView {
  .subtitle {
    font-size: $font-size-large;
    color: $blue1;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 20px;
  }

  > .inner {
    h1 {
      margin: 0 0 20px 0 !important;

      span {
        font-weight: inherit;
        display: inline;

        @media (max-width: $breakpoint-mobile) {
          display: block;
        }
      }
    }

    p {
      color: $font-color-muted;
    }
  }
}

.blogPost,
.pricingPage {
  background-color: rgba($white, 0.9);

  .inner {
    position: relative;
  }

  .filters {
    position: absolute;
    top: 82px;
    right: 30px;
    display: flex;
  }

  > .inner {
    max-width: 760px;

    > hr {
      margin: 30px 0 30px !important;
    }

    > h1 {
      font-size: 56px !important;
      line-height: 1.05;
    }

    > h2 {
      font-size: 22px !important;
    }

    > h3 {
      font-size: 18px !important;
      line-height: 24px;
      text-transform: uppercase;
      color: $font-color-muted;
    }

    > h4 {
      text-transform: uppercase;
      font-size: 13px;
      color: $font-color-muted;
      line-height: 18px;
    }

    > .goBack {
      display: flex;
      align-items: center;
      color: $blue1 !important;
      margin-bottom: 40px !important;
      cursor: pointer;
    }

    .list {
      display: flex;
      flex-direction: column;

      .post {
        @include elevation-2();
        margin-bottom: 20px;
        border-radius: $border-radius;
        padding: 40px;

        .heading {
          display: flex;
          align-items: center;
          margin-bottom: 25px;

          .label {
            margin-left: 8px;
            text-transform: uppercase;
            color: $font-color-muted;
            font-weight: 600;
          }
        }

        h2 {
          display: inline-block;
          padding: 3px 6px 2px !important;
          margin: 0 -4px 25px !important;
          color: $link-color;
          font-size: 30px;
          font-weight: 600;
          border-radius: $border-radius;
          position: relative;
          cursor: pointer;
          transition: background-color $anim-duration $anim-easing;

          &::before {
            content: '';
            height: 1px;
            background-color: rgba($link-color, 0.3);
            position: absolute;
            left: 5px;
            right: 4px;
            bottom: 0;
            opacity: 1;
            transition: opacity $anim-duration $anim-easing;
          }

          &:hover {
            background-color: rgba($link-color, 0.1);
            border-color: transparent;

            &::before {
              opacity: 0;
            }
          }
        }

        p:last-child {
          margin-bottom: 0 !important;
        }

        .bottom {
          display: flex;
          align-items: center;
        }

        .tagList {
          margin-right: auto;

          > div {
            margin: 3px 3px 3px 0;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .social {
      margin-bottom: 30px;

      :global(.twitter-tweet.twitter-tweet-rendered),
      :global(.embedly-card-hug) {
        margin: 0 !important;
      }
    }

    p,
    ol > li,
    ul > li {
      font-size: 18px;
      line-height: 26px;

      strong {
        font-weight: 600;
      }
    }

    h1,
    h2,
    h3,
    h4,
    ul,
    ol,
    p {
      margin: 0 0 30px !important;
    }

    h1,
    h2,
    h3,
    h4 {
      padding-top: 15px !important;
    }

    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    figcaption {
      text-align: center;
      margin-top: -10px;
      margin-bottom: 30px;
      color: $font-color-muted;

      a {
        color: $font-color-muted;
        text-decoration: underline;
      }
    }

    blockquote {
      position: relative;
      border-left: 1px solid $border-color;
      margin: 0 0 30px 10px;
      padding-left: 30px;
      font-style: italic;

      &::before {
        content: '“';
        color: $yellow1;
        font-size: 62px;
        font-style: normal;
        font-weight: 900;
        height: 30px;
        line-height: 68px;
        padding: 0 5px;
        background-color: $white;
        position: absolute;
        left: -17px;
        top: -2px;
      }

      a {
        color: $font-color-muted;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .blogPost {
    .list {
      .post {
        padding: 30px !important;
      }
    }
  }
}
