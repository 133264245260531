@import '../variables.scss';

.meetingInvite {
  display: flex;
  position: relative;
  min-width: 0;

  $calBorder: rgba($gray1, 0.3);

  &.goodToBad {
    .invite.bad {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  &.badToGood {
    .invite.bad {
      opacity: 1;
      transform: scale(1);
    }
  }

  .lines {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .line {
      height: 54px;
      border-top: 1px solid $calBorder;

      &:first-child {
        border-top: none;
      }
    }
  }

  .days {
    display: flex;
    flex: 1 1 auto;
    z-index: 1;
    min-width: 0;

    .day {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      padding: 5px 3px;
      border-left: 1px solid $calBorder;
      min-width: 0;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .invite {
    @include elevation-2();
    border-radius: $border-radius;
    border: 1px solid $red1;
    color: $red1;
    padding: 9px 10px;
    height: 101px;
    margin-bottom: 6px;
    min-width: 0;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }

    &.bad {
      transition: opacity $anim-duration * 2 $anim-easing,
        transform $anim-duration * 2 cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    &.accepted {
      background: rgba($gray1, 0.5);
      color: $black;
      border: none;
    }

    &.empty {
      border: transparent;
      background: transparent;
      box-shadow: none;

      > * {
        display: none;
      }
    }

    &.hour {
      height: 47px;
    }

    .title {
      margin-bottom: 1px;
      flex: none;

      @media (max-width: $breakpoint-mobile) {
        @include text-overflow-ellipsis();
      }
    }

    .date {
      flex: none;
      opacity: 0.6;
      min-width: 0;
    }
  }

  &.isVisible {
    &.goodToBad {
      .invite.bad {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.badToGood {
      .invite.bad {
        opacity: 0.2;
        transform: scale(0.9);

        &[data-disappear-order='1'] {
          transition-delay: 1s;
        }

        &[data-disappear-order='2'] {
          transition-delay: 2s;
        }

        &[data-disappear-order='3'] {
          transition-delay: 3s;
        }

        &[data-disappear-order='4'] {
          transition-delay: 4s;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .meetingInvite {
    padding: 0 20px;
  }
}
