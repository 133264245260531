@import './variables.scss';

.tipView {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  transition: opacity $anim-duration $anim-easing;

  .inner {
    @include elevation-3();
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    max-width: 300px;
    border-radius: 6px;
    transition: transform $anim-duration $anim-easing,
      opacity $anim-duration $anim-easing;

    &.isLarge {
      width: 350px;
      max-width: 350px;
      margin-left: -45px;

      .title {
        font-size: $font-size-extra-large;
        font-weight: 700;
        margin: 0 0 12px 0;
      }

      .description {
        font-size: $font-size;
        line-height: 20px;

        > p {
          margin: 0 0 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          margin: 0;
          padding-left: 20px;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    &.forView {
      width: 400px;
      max-width: 400px;
      top: 86px !important;
      left: calc(50% - 200px) !important;
    }

    .header {
      display: flex;
      align-items: center;
      margin: 0 0 15px 0;
    }

    .label {
      margin: 0;
      color: $blue1;
      font-size: $font-size-small;
      text-transform: uppercase;
    }

    .steps {
      margin-left: auto;
      color: $font-color-muted;
    }

    .title {
      font-weight: 600;
      margin: 0 0 6px 0;
    }

    .description {
      font-size: $font-size-small;
      color: $font-color-muted;
      line-height: 16px;
      margin: 0 0 15px 0;
    }
  }

  .bkg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.tipHighlight {
  position: fixed;
  box-shadow: 0 0 0 1px $blue1, 0 0 0 200vh rgba($blue1, 0.35);
  z-index: 999999998;
  border-radius: $border-radius;
  transition: opacity $anim-duration $anim-easing;
}
