@import './variables.scss';

.social {
  margin-bottom: 30px;

  :global(.twitter-tweet.twitter-tweet-rendered),
  :global(.embedly-card-hug) {
    margin: 0 !important;
  }

  blockquote {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-style: normal !important;

    *,
    &::before {
      display: none;
    }

    &::after {
      height: 8px;
      border-radius: $border-radius;
      background-color: $border-color;
      content: '';
      display: block;
      width: 200px;
    }
  }

  .giphy {
    height: 500px;
  }
}
