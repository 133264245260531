@import './variables.scss';

.calendarView {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 0;

  $header-height: 70px;
  $subnav-height: 76px;

  .missingMeetings {
    top: $subnav-height;
    overflow: auto;
    z-index: 9999;

    > div {
      margin: auto;
    }
  }

  .subNav {
    display: grid;
    grid-template-columns: auto auto auto;
    flex: 0 0 auto;
    height: $subnav-height;
    padding: 0 20px;
    border-bottom: 1px solid $border-color;
    z-index: 2;

    > * {
      user-select: none;
    }

    .navBtns {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }

    .todayBtn {
      margin-right: 10px;
    }

    .prevNextBtns {
      display: flex;
      justify-content: space-between;
      width: 75px;
      margin-right: 10px;
    }

    .calendarRange {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      height: 100%;

      > .text {
        font-size: $font-size-extra-large;
        font-weight: 600;
      }
    }

    .addWellnessBlock {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .calendarAndStats {
    display: flex;
    flex: 1 1 auto;
    min-height: 0;

    > .quickStatsView {
      flex: none;
      display: flex;
      flex-direction: column;
      max-width: 330px;
      width: 100%;
      padding: 20px;
      overflow: auto;
      border-right: 1px solid $border-color;

      div[data-label='description'] {
        display: none;
      }
    }

    > .calendar {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      padding: 20px;

      h5 {
        color: $blue1 !important;
      }
    }
  }

  :global(.rbc-calendar) {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;

    > :global(.rbc-time-view) {
      border: none;
      border-radius: $border-radius;
      @include elevation-2();
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    :global(.rbc-today) {
      background-color: transparent !important;
    }

    * {
      font-size: $font-size-small;
      border-color: $border-color !important;
      outline: none !important;
    }
  }

  :global(.rbc-day-slot .rbc-time-slot) {
    border-color: rgba($border-color, 0.3) !important;
  }

  :global(.rbc-time-slot):first-child {
    border-color: transparent !important;
  }

  :global(.rbc-time-gutter .rbc-time-slot:first-child) {
    padding-top: 5px;
  }

  :global(.rbc-time-content) {
    border-width: 1px;
    overflow: hidden;
  }
}

:global(.rbc-toolbar) {
  margin: 0;
  height: 50px;
  padding: 0 10px;

  :global(.rbc-toolbar-label) {
    font-size: $font-size-large;
    font-weight: 600;
  }
}

:global(.rbc-btn-group) {
  @include click-elevation();

  > button {
    border: none;
    border-radius: 0;
  }
}

:global(.rbc-header) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-weight: 600;

  > a {
    color: $font-color !important;
  }
}

:global(.rbc-events-container) {
  margin-right: 0;
}

:global(.rbc-current-time-indicator) {
  left: -100vw;
  right: -100vw;
  top: -100vh;
  background-color: $secondary-color;
}

:global(.rbc-event) {
  z-index: 1;
  position: relative;
  border-radius: 0;
  background: rgba($white, 0.8);
  border: none !important;
  color: $primary-color;
  padding: 7px 7px 0;
  overflow: visible !important;

  &:global(.rbc-selected) {
    background: none;
  }

  :global(.rbc-event-content) {
    overflow: hidden;
  }

  &:hover .eventBkg {
    opacity: 1;
  }
}

:global(.rbc-event-label) {
  display: none;
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .calendarView {
    .subNav {
      display: flex;
      flex-direction: row-reverse;

      .calendarRange {
        justify-content: flex-start;

        .text {
          font-size: $font-size-large;
        }
      }

      .addWellnessBlock {
        display: none;
      }
    }

    .calendarAndStats {
      flex-direction: column;
      padding: 20px;
      overflow: auto;

      .day {
        margin-bottom: 15px;
      }

      .eventItem {
        position: relative;
        padding: 10px;
        margin-bottom: 10px;
        max-height: 100px;

        > div {
          min-height: 22px;
          max-height: 80px;
          overflow: hidden;
        }
      }
    }
  }
}
