@import './variables.scss';

.modalView {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 9999998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($black, 0.3);
  overflow: auto;
  transform: translate3d(0, 0, 0);
  transition: opacity $anim-duration $anim-easing;

  &.floating {
    align-items: flex-start;
    justify-content: flex-start;

    .inner {
      left: 20%;
      top: 30%;
      width: 760px;
      max-width: 760px;
      min-width: 760px;
      height: 620px;
      min-height: 620px;
      max-height: 620px;
      border-radius: 6px;
      overflow: hidden;
      margin: 0;
    }
  }

  &.drawer {
    align-items: flex-end;
    justify-content: flex-start;

    .inner {
      border-radius: 6px 0 0 6px;
      width: 40%;
      max-width: 400px;
      height: 100%;
      overflow: auto;
      min-width: 400px;
      margin: 0;
    }
  }

  .inner {
    @include elevation-3();
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px;
    border-radius: 6px;
    margin: auto;
    transition: transform $anim-duration $anim-easing,
      opacity $anim-duration $anim-easing;

    .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .bkg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .modalView.floating {
    display: flex;
    align-items: center;

    .inner {
      top: 20px !important;
      left: auto !important;
      right: auto !important;
      width: 100% !important;
      min-width: 60vw !important;
      max-width: 90vw !important;
      max-height: 90vh !important;
      overflow: auto;
      overflow-x: hidden;
      margin: 0 !important;
    }
  }

  .modalView.drawer {
    display: flex;
    align-items: center;

    .inner {
      top: 20px !important;
      left: auto !important;
      right: auto !important;
      width: 100% !important;
      min-width: 60vw !important;
      max-width: 90vw !important;
      max-height: 90vh !important;
      overflow: auto;
    }
  }
}
