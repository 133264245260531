@import './variables.scss';

.header {
  font-family: 'Vollkorn', sans-serif;
  flex: none;
  display: grid;
  grid-template-columns: 30% auto 30%;
  position: relative;
  height: 70px;
  padding: 0 12px 0 20px;
  background-color: $white;
  border-bottom: 1px solid rgba($purple2, 0.08);
  overflow: hidden;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;

  .appNav,
  .mktgNav {
    display: flex;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > a {
    display: block;
    height: 32px;
    width: 127px;
    background-image: url(../images/logo-nav.svg);
    background-size: 100% auto;
  }
}

.link {
  position: relative;
  display: flex;
  font-size: $font-size-extra-large;
  flex: 0 0 auto;
  margin: 0 10px;
  padding: 10px 16px;
  border-radius: $border-radius;
  text-decoration: none;
  font-weight: 400;
  color: $gray1;
  transition: background-color $anim-duration $anim-easing;

  &:hover {
    background: lighten($gray2, 6%);

    .text {
      color: darken($gray1, 12%);
    }
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 49px;
    height: 49px;
    border: 1px solid #e5ebf3;
    border-radius: 50%;
    left: 7px;
    top: 1px;
    opacity: 0;
    transform: scale(0.7);
    transition: transform $anim-duration $anim-easing;
  }

  &::before {
    width: 79px;
    height: 79px;
    left: -8px;
    top: -13px;
  }

  .text {
    display: flex;
    align-items: center;
    margin-left: 6px;
    padding-top: 4px;
    transition: color $anim-duration $anim-easing;
  }

  &.active {
    background-color: transparent;

    &::after {
      opacity: 0.5;
      transform: scale(1);
    }

    &::before {
      opacity: 0.35;
      transform: scale(1);
    }

    .text {
      color: $black;
      z-index: 2;
      position: relative;

      &::after {
        opacity: 0;
      }
    }
  }
}

.rightInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Catamaran', sans-serif;

  .autoBalanceInfo {
    display: flex;
    padding-right: 15px;
    flex: 0 0 auto;
    padding: 6px 8px;
    border-radius: $border-radius;
    user-select: none;
    cursor: pointer;
    transition: background-color $anim-duration $anim-easing;
    min-height: 49px;

    &:hover {
      background: lighten($gray2, 5%);
    }

    h6 {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      font-size: 10px;
      color: $font-color-muted;
      text-transform: uppercase;
      margin: 0;
      width: 45px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      min-height: 100%;
      margin-right: 10px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .actionTag {
        margin-bottom: 6px;
      }

      .label {
        font-weight: 600;
        font-size: $font-size-small;
        line-height: 8px;
        padding: 1px 0;
        margin: 0 0 0 4px !important;
      }
    }
  }

  @media (max-width: $breakpoint-medium) {
    .autoBalanceInfo {
      flex-direction: column;

      h6 {
        width: auto;
        border: none;
        margin: 0 0 3px;
      }
    }

    .label {
      display: none;
    }
  }
}

.divider {
  height: 40px;
  width: 1px;
  background-color: $border-color;
  margin: 10px;
}

.userInfoPopover {
  flex: 0 0 auto;
}

.userInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .userInfo {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 6px 8px;
    border-radius: $border-radius;
    user-select: none;
    cursor: pointer;
    transition: background-color $anim-duration $anim-easing;

    &:hover {
      background: lighten($gray2, 5%);
    }

    .image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;

      > img,
      > span {
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($gray2, 0.3);
      }

      .accountIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .text {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
    }

    .name {
      font-size: $font-size-large;
      font-weight: 600;
      margin-bottom: -2px;
    }

    .plan {
      display: flex;
      align-items: center;
      color: $font-color-muted;
      font-size: $font-size-small;
      line-height: 20px;

      .countdown {
        margin-left: 7px;
      }
    }

    @media (max-width: 1000px) {
      .text {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .header {
    grid-template-columns: auto auto auto;
    border-radius: 20px 20px 0 0;
    border-bottom: none;
    border-top: 1px solid rgba($purple2, 0.08);
  }

  .appHeader {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;

    .logo {
      display: none;
    }

    .nav {
      .link {
        margin: 0;
        padding: 10px 20px;

        &::after {
          left: 10px;
        }

        &::before {
          left: -5px;
        }

        .text {
          display: none;
        }
      }
    }

    .rightInfo {
      .autoBalanceInfo,
      .divider {
        display: none;
      }

      .userInfoWrapper {
        .userInfo {
          padding: 10px 20px;
        }

        .image {
          margin: 0;
        }

        .name,
        .plan {
          display: none;
        }
      }
    }
  }
}
