@import '../variables.scss';

.pullQuote {
  background: $gray0;
  padding: 35px 0 40px;

  &.noMargin {
    margin: 0;
  }

  .inner {
    max-width: 590px;
    padding: 0 30px;
  }

  .mark {
    color: $purple1;
    font-size: 72px;
    font-weight: 900;
    height: 40px;
    margin-bottom: 10px;
  }

  .text {
    color: $white;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .author {
    color: rgba($white, 0.35);
    font-size: 20px;

    a {
      color: rgba($white, 0.35);
      text-decoration: underline;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .text {
      font-size: 22px;
    }

    .author {
      font-size: 16px;
    }
  }
}
