@import './variables.scss';

.shareTextOuterModal {
  align-items: center !important;
  justify-content: center !important;
}

.shareTextModal {
  display: flex;
  flex-direction: column;
  left: auto !important;
  top: auto !important;
  width: 500px !important;
  max-width: 500px !important;
  min-width: 500px !important;
  height: auto !important;
  min-height: 10vh !important;
  max-height: 80vh !important;
  margin-left: 0 !important;
  margin-top: 0 !important;

  .descriptor {
    color: $font-color-muted;
    text-align: center;
    margin-bottom: 20px;
    line-height: 18px;
  }

  .textToShare {
    flex: 1 1 auto;
    overflow: auto;
    border: 1px solid $border-color;
    border-radius: 3px;
    background-color: rgba($border-color, 0.3);
    padding: 20px;
    font-size: $font-size-large;

    p,
    hr {
      margin-bottom: 20px;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    display: flex;
    margin-top: auto;
    padding-top: 20px;

    > div {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
