@import './variables.scss';

.chart {
  height: 30px;
  width: 100px;
  flex: none;
  position: relative;
  z-index: 8;

  &.danger {
    $line-color: #ff3146;
    > div > svg :global(g.Series) {
      path {
        stroke: $line-color !important;
      }

      circle {
        stroke: $line-color !important;
        fill: $line-color !important;
      }
    }
  }

  &.caution {
    $line-color: #fed93a;
    > div > svg :global(g.Series) {
      path {
        stroke: $line-color !important;
      }

      circle {
        stroke: $line-color !important;
        fill: $line-color !important;
      }
    }
  }

  &.warning {
    $line-color: #ff8a00;
    > div > svg :global(g.Series) {
      path {
        stroke: $line-color !important;
      }

      circle {
        stroke: $line-color !important;
        fill: $line-color !important;
      }
    }
  }

  &.success {
    $line-color: #1db08c;
    > div > svg :global(g.Series) {
      path {
        stroke: $line-color !important;
      }

      circle {
        stroke: $line-color !important;
        fill: $line-color !important;
      }
    }
  }

  > div > svg {
    overflow: visible !important;
  }
}
