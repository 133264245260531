@import './variables.scss';

:global(.sortableHelper) {
  z-index: 9999999999999999;
  display: flex;
  align-items: center;
}

.eventModalWrapper {
  display: flex;
  padding: 0 !important;
  top: 50% !important;
  left: 50% !important;
  margin: -310px 0 0 -380px !important;

  &.isWellnessBlock {
    height: 190px !important;
    min-height: auto !important;
  }
}

.eventModalInner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sectionLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  background-color: rgba($white, 0.7);
  transition: opacity $anim-duration $anim-easing;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.sectionTop {
  flex: none;
  border-bottom: 1px solid $gray2;
  padding: 30px 30px 20px;

  .title {
    display: flex;
  }

  h2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 30px;
    margin: 0 0 10px;
    font-weight: 800;
  }

  .deletedMeetingTag {
    margin-left: 10px;
  }

  .options {
    flex: none;
    margin: -5px -5px 0 auto;
  }
}

.sectionBottom {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  padding: 20px 30px 0;
  overflow: auto;
  overflow-x: hidden;
  min-height: 0;
  min-width: 0;

  &.dailyReport {
    background-color: rgba($gray1, 0.08);

    > div {
      flex: 1 1 0;
      padding: 0;

      &:first-child {
        margin-right: 30px;
      }
    }
  }

  .sectionBottomInner {
    display: flex;
    height: min-content;
    width: 100%;
  }

  .sectionLeft {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    padding-right: 20px;
    height: min-content;
  }

  .sectionRight {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: none;
    padding-left: 20px;
    width: 60%;
    min-width: 60%;
    max-width: 60%;
    height: min-content;
  }

  .noEvalState {
    height: 250px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-left: 20px;
  }
}

.sectionActions {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  background-color: $white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px -1px 0px rgba($black, 0.05), 0px -1px 8px rgba($black, 0.1);

  h5 {
    font-size: $font-size-small;
    border-right: 1px solid $border-color;
    margin-right: 5px;
    padding-right: 15px;
    line-height: 18px;
  }

  .tick {
    margin-right: 3px;
    display: inline-block;
  }

  .explainerTip {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    color: $font-color-muted;

    .explainerText {
      border-bottom: 1px dotted $font-color-muted;
    }

    a {
      border-bottom: 1px solid transparent;
      color: $link-color;
      border-left: 1px solid $border-color;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
}

.miscInfo {
  display: flex;
  flex-direction: column;

  .metaData {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .intention {
      display: flex;
      align-items: center;
      padding: 5px 4px;
      border-radius: $border-radius;
      border: 1px solid $border-color;
      margin-right: 5px;
      font-weight: 600;

      span {
        height: 18px;
        line-height: 19px;
      }
    }

    .labels {
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: $border-radius;
      border: 1px solid $border-color;
      margin-right: 5px;

      div {
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .meetingCost {
      margin-right: auto;
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;
  position: relative;
  transition: opacity $anim-duration $anim-easing;

  &.fadeOut {
    opacity: 0.3;
    pointer-events: none;
  }

  &.isWellnessBlock {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &:empty {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h6 {
      margin: 0 8px 0 0;
      line-height: 16px;
      font-size: $font-size-small;
      text-transform: uppercase;
    }

    .link {
      margin-left: auto;
      background: $white;
    }

    .how {
      margin-left: auto;
      margin-right: -5px;
    }
  }

  .item {
    display: flex;
    padding: 0;

    &.sectionDescription {
      position: relative;

      .link {
        margin-top: 8px;
      }

      &.collapsed {
        max-height: 35px;
        overflow: hidden;

        .collapseButton {
          background: linear-gradient(
            0deg,
            rgba($white, 1) 0%,
            rgba($white, 0) 100%
          );
        }
      }
    }

    &.sectionAttendees {
      .attendeeInfo {
        flex: 1 1 auto;
        min-width: 0;
      }

      .text {
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
      }

      .attendeeList {
        flex: 1 1 auto;
        padding-top: 11px;
        position: relative;
        margin-left: -30px;

        .inner {
          max-height: 120px;
          overflow: auto;
          padding-left: 30px;
        }

        .gradient {
          position: absolute;
          height: 30px;
          left: 0;
          bottom: 0;
          right: 0;
          pointer-events: none;
          z-index: 2;
          background: linear-gradient(
            0deg,
            rgba($white, 1) 0%,
            rgba($white, 0) 100%
          );
        }

        .attendee {
          display: flex;
          align-items: center;
          height: 16px;
          line-height: 13px;
          position: relative;
          margin-bottom: 10px;

          .email {
            @include text-overflow-ellipsis();
          }

          .youMarker {
            flex: none;
            margin: 0 7px 0 4px;
          }

          .role,
          .optional {
            flex: none;
            margin-left: auto;
            font-size: $font-size-small;
            color: $font-color-muted;
          }
        }
      }

      .inviteStatusTag {
        position: absolute;
        left: -27px;

        .inviteStatus {
          padding: 0;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            height: auto;
          }
        }
      }
    }

    > .icon {
      align-self: flex-start;
      margin-right: 10px;
    }

    > .text {
      height: auto;
      overflow: hidden;
    }

    .collapseButton {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 20px;
      bottom: 0;
      right: 0;
      background: none;
      pointer-events: none;

      .link {
        background: rgba($white, 0.9);
        padding: 2px 4px;
        border-radius: 2px;
        margin: 6px -4px -2px;
        pointer-events: all;
      }
    }
  }

  .emptyText {
    color: $font-color-muted;
  }
}

.importNote {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
      font-weight: 600;
      color: $black;
      margin-right: auto;
      line-height: 15px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .availMetaData {
    span.val {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

.agendaItems {
  display: flex;
  flex-direction: column;

  .agendaItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .description {
      display: flex;
      height: auto;
      margin-right: auto;

      .num {
        flex: 0 0 auto;
      }

      .text {
        padding-left: 7px;
      }
    }

    .duration {
      flex: 0 0 auto;
      color: $font-color-muted;
    }
  }
}

.objectiveItems {
  display: flex;
  flex-direction: column;

  .objectiveItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .description {
      display: flex;
      height: auto;
      margin-right: auto;

      .num {
        flex: none;
        line-height: 18px;
      }

      .text {
        padding-left: 7px;
        line-height: 18px;
      }
    }
  }
}

.preWorkItems {
  display: flex;
  flex-direction: column;

  .preWorkItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .description {
      display: flex;
      height: auto;
      margin-right: auto;

      .num {
        flex: 0 0 auto;
      }

      .text {
        padding-left: 7px;
      }
    }

    .duration {
      flex: 0 0 auto;
      color: $font-color-muted;
    }
  }
}

.scoreVisual {
  flex: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .scoreNum {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    .num {
      font-size: 32px;
      flex: 0 0 auto;
      font-weight: 800;
      margin-bottom: -3px;
    }

    .total {
      font-size: $font-size-large;
      flex: 0 0 auto;
      color: $font-color-muted;
      font-weight: 600;
      margin-left: 5px;
    }

    .desc {
      font-size: $font-size-small;
      flex: 1 1 auto;
      color: $font-color-muted;
      margin-left: 16px;
      line-height: 16px;
    }
  }

  .scoreBar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 3px;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      background-color: $gray2;
      height: 1px;
      width: 100%;
    }

    .colorBar {
      position: relative;
      z-index: 2;
      width: 0;
      height: 1px;
      background-color: $gray2;
      transition: width $anim-duration $anim-easing,
        background-color $anim-duration $anim-easing;
    }
  }
}

.scoreList {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  > .header {
    display: flex;
    align-items: center;

    h6 {
      flex: 1 1 auto;
    }

    > div {
      text-transform: uppercase;
      font-size: 10px;
      color: $font-color-muted;
      font-weight: 700;
    }

    .score {
      flex: 0 0 60px;
    }

    .points {
      flex: 0 0 65px;
    }
  }
}

.scoreSettings {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.showMeHow {
  margin: 15px 0 -5px;
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .sectionTop {
    padding: 20px 20px 15px;

    .title h2 {
      padding-right: 20px;
    }

    .miscInfo {
      .meetingCost {
        margin-left: 5px;
      }
    }
  }

  .eventModalInner {
    .metaData {
      align-items: flex-start;
      flex-wrap: wrap;

      .sectionIntention {
        margin-bottom: 5px;
      }
    }

    .sectionBottom {
      flex-direction: column;

      .sectionBottomInner {
        flex-direction: column;
      }

      .sectionLeft {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
      }

      .sectionRight {
        padding: 0;
        border-top: 1px solid $border-color;
        padding-top: 30px;
        margin-top: 0;
        width: 100%;
        max-width: 100%;
      }

      .noEvalState {
        margin: 30px 0 0 0;
      }
    }
  }
}
