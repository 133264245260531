@import './variables.scss';

.teamMgmtView {
  overflow: auto;
  width: 100%;
  padding: 30px;

  .inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    h1 {
      margin-bottom: 25px;
    }
  }

  .group {
    flex: 1 1 auto;
    display: flex;
    margin-bottom: 30px;

    &.billingActions {
      .column {
        width: 35%;

        &:last-child {
          width: 65%;
        }
      }
    }

    .column {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      width: 65%;

      &:last-child {
        width: 35%;
        margin-right: 0;
      }
    }
  }
}

.section {
  @include elevation-2();
  border-radius: $border-radius;
  margin-bottom: 30px;
  padding: 25px;

  h5 {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: $blue1;

    span {
      margin-left: auto;
      color: $font-color-muted;
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &.billing {
    max-width: 750px;
  }
}

.actions {
  padding-top: 10px;
}

.table {
  padding-top: 10px;

  .header {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 10px;

    span {
      flex: 1 1 0;
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      margin-right: 20px;

      &:nth-child(3) {
        flex: 0 1 auto;
        width: 100px;
      }

      &:last-child {
        flex: 0 1 auto;
        width: 30px;
        margin-right: 0;
      }
    }
  }

  .row {
    display: flex;
    padding: 10px 0;
    position: relative;

    > span {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      margin-right: 20px;

      &.email {
        color: $font-color-muted;
      }

      &:nth-child(3) {
        flex: 0 1 auto;
        width: 100px;
      }

      &:last-child {
        flex: 0 1 auto;
        width: 30px;
        margin-right: 0;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.upcomingCancel {
  display: flex;
  align-items: center;

  .warningEmoji {
    margin-right: 15px;
  }

  .warningDesc {
    margin-right: 10px;
    line-height: 19px;
  }

  .reactivateButton {
    margin-left: auto;
  }
}

.teamName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teamNameInput {
  margin-bottom: 15px;
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .teamMgmtView {
    .group {
      flex-direction: column;

      .column {
        width: 100% !important;
        margin: 0;
      }
    }

    .table {
      padding-top: 0;

      .header {
        display: none;
      }

      .row {
        flex-wrap: wrap;

        span {
          margin: 0;
          flex: none;

          &.name {
            width: 100%;
            margin-bottom: 3px;
          }

          &.email {
            width: 100%;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
