@import './variables.scss';

.actionInfo {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 313px;

  .card {
    @include elevation-2();
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $border-color;
    padding: 17px 20px;

    .ignoreEnforce {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .actionNote {
    margin-top: 15px;

    span {
      font-weight: 500;
      text-decoration: underline;
    }

    .link {
      display: inline-block;
      margin-top: 6px;
    }
  }

  .futureAndPastActions {
    padding: 25px 0;

    .card {
      margin-bottom: 25px;
      overflow: auto;
      max-height: 226px;
      justify-content: flex-start;
    }

    .action {
      padding: 8px 12px 12px 15px;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .tag {
          margin-left: -5px;
        }

        .organizer {
          @include text-overflow-ellipsis();
          flex: 1 1 auto;
          padding-left: 8px;
          font-weight: 600;
          margin-right: auto;
        }

        .actions {
          flex: none;
          display: flex;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        color: $font-color-muted;
        font-size: $font-size-small;

        > * {
          margin-right: 12px;

          &:last-child {
            margin-right: 4px;
          }
        }

        .name {
          @include text-overflow-ellipsis();
        }

        .violations {
          flex: none;
          box-shadow: inset 0 -1px 0 0 rgba($font-color-muted, 0.6);
          margin-right: auto;
        }

        .time {
          flex: none;
          padding-left: 12px;
        }
      }
    }

    .empty {
      color: $font-color-muted;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Mobile styles
@media (max-width: $breakpoint-mobile) {
  .actionInfo {
    width: 100%;
    max-width: none;

    .card {
      .info {
        display: flex;
        flex-direction: row;

        .content {
          align-items: flex-start;
          justify-content: center;
          padding-left: 20px;
          margin: 0;
          border-top: none;
          background: none;

          > div {
            text-align: left;
          }
        }
      }
    }
  }
}
