@import './variables.scss';

.tooltipWrapper {
  position: relative;
}

.tooltip {
  display: flex;
  pointer-events: none;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  background: rgba($black, 0.95);
  border-radius: $border-radius;
  padding: 6px 9px;
  width: auto;
  max-width: 320px;
  min-width: 0;

  .text {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    line-height: 16px;
    color: $white;
    margin: 0;
    min-width: 0;
  }

  span {
    // A plan "thought"
    display: flex;
    font-style: italic;
    min-width: 0;
    flex: none;
    opacity: 0.6;
  }

  ul {
    margin: 0;
    padding-left: 15px;
  }

  :global(.exampleScreenshot) {
    @include elevation-3();
    border-radius: $border-radius;
    background-color: $white;
    width: 285px;
    height: 202px;
    background-size: 100% auto;
    margin: 7px 0 3px;
    min-width: 0;
    flex: none;
    position: relative;

    &::before {
      content: 'EXAMPLE';
      display: block;
      position: absolute;
      top: 3px;
      right: 3px;
      font-size: 10px;
      font-weight: 600;
      color: $white;
      background-color: $black;
      opacity: 0.8;
      border-radius: 2px;
      padding: 1px 4px 0;
    }

    &:global(.evaluations) {
      background-image: url(../images/examples/score-B.png);
    }

    &:global(.reports) {
      background-image: url(../images/examples/reports.png);
    }

    &:global(.wellnessBlocks) {
      background-image: url(../images/examples/wellnessBlocks.png);
    }

    &:global(.dailyReports) {
      background-image: url(../images/examples/daily-report-card.png);
      height: 146px;
    }

    &:global(.weeklyEmails) {
      background-image: url(../images/examples/weekly-report.png);
    }

    &:global(.bettermentIdeas) {
      background-image: url(../images/examples/bettermentIdeas.png);
    }

    &:global(.personalize) {
      background-image: url(../images/examples/personalize.png);
    }

    &:global(.manualAction) {
      background-image: url(../images/examples/manualAction.png);
    }

    &:global(.improvementReminders) {
      background-image: url(../images/examples/remind-email.png);
    }

    &:global(.autoDeclineMeeting) {
      background-image: url(../images/examples/autoDeclineMeeting.png);
    }

    &:global(.autoBalanceExample) {
      background-image: url(../images/examples/autoBalanceExample.jpg);
    }
  }
}
