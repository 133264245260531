@import './variables.scss';

.item {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  &:last-child .section {
    margin-bottom: 0;
  }

  > h6 {
    display: flex;
    align-items: center;
    color: $font-color-muted;
    font-size: 10px !important;
    margin-top: 13px !important;
    width: 100%;

    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: $border-color;
      flex: 1 1 auto;
      margin-left: 5px;
    }
  }

  > .section {
    flex: 1 1 auto;
    display: flex;
    align-items: baseline;
    width: 100%;
    min-height: 20px;
    margin-bottom: 8px;

    .label {
      flex: none;
      color: $font-color-muted;
      width: 30%;
      height: 20px;
      line-height: 18px;

      > span {
        border-bottom: 1px dotted $font-color-muted;
      }
    }

    .content {
      flex: 1 1 auto;
      position: relative;
      display: flex;
      align-items: baseline;
      width: 70%;
      min-height: 20px;

      .domainTag,
      .organizerTag {
        flex: 1 1 auto;
        margin-bottom: 5px;
        margin-right: 5px;
        min-width: 0;
        max-width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        > div {
          @include text-overflow-ellipsis();
        }
      }

      .editWrapper {
        flex: none;
        align-items: baseline;
        margin-left: 10px;
        position: absolute;
        top: -1px;
        right: -4px;
        background: $white;
        padding: 4px;

        .edit {
          > div {
            line-height: 18px;
          }
        }
      }
    }

    &.deadlineEnforcement {
      .muted {
        font-weight: 400;
        color: $font-color-muted;
      }

      .when {
        margin-bottom: 3px;
        padding: 3px 0 2px;
        line-height: 17px;
      }

      .action {
        display: flex;
        font-weight: 400;
        color: $font-color-muted;
        padding-left: 2px;
        font-size: $font-size-small;
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          line-height: 16px;
          padding-top: 2px;
          padding-left: 5px;

          .link {
            text-decoration: underline;
            cursor: pointer;
            padding: 0;
            transition: background-color $anim-duration $anim-easing;

            &:hover {
              background-color: rgba($gray2, 0.3);
            }
          }
        }
      }
    }

    &.domainEnforcement,
    &.enforcementAction,
    &.organizerEnforcement {
      position: relative;
    }

    &.enforcementAction {
      flex-direction: column;

      .instruction {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        line-height: 20px;
      }

      .content {
        width: 100%;
      }
    }
  }

  .text {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 20px;
    line-height: 18px;
    font-weight: 600;
    font-family: 'Catamaran', sans-serif;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      font-weight: 600;

      > div:first-child {
        margin-right: 7px;
      }

      .actionLabel {
        @include text-overflow-ellipsis();
        line-height: 17px;
      }
    }

    .description {
      color: $font-color-muted;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}

.addVIPDialog {
  max-width: 300px;

  .areYouSure {
    margin: 15px 0 0 0;
  }
}
