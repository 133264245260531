@import '../variables.scss';

.areYouReady {
  padding-top: 80px;
  margin-bottom: 100px;

  h2 {
    padding: 0 30px;

    span {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background-color: $purple1;
        border-radius: 4px;
        left: 0;
        right: 0;
        bottom: -4px;
      }
    }
  }
}
