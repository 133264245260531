@import './variables.scss';
@import './review.module.scss';

.reportCardStats {
  @include review-styles-section();

  .moduleOverall {
    flex-shrink: 1;
    padding: 30px 0 30px 30px;
    width: 25%;
    max-width: 230px;

    .title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 200;

      strong {
        font-weight: 600;
      }
    }

    .content {
      margin-top: 20px;
    }

    .progressChart {
      margin: 0 0 18px;

      > div {
        height: 30px;
        width: 100%;
      }
    }

    .data {
      display: flex;
      align-items: center;
      margin-bottom: 17px;
    }

    .metric {
      flex: 0 0 auto;
      font-size: 72px;
      font-weight: 800;
      margin: 0;
      height: 54px;
      overflow: hidden;
      line-height: 54px;

      &.perfectScore {
        font-size: 54px;
      }
    }

    .misc {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      padding-left: 7px;

      .delta {
        padding: 0;
        margin-top: 2px;
      }

      .grade {
        justify-content: center;
        padding: 0 12px;
        height: 24px;
      }

      .total {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        justify-content: space-between;
        color: $font-color-muted;

        span {
          margin-right: 10px;
        }
      }
    }

    .description {
      color: $font-color-muted;
      font-size: $font-size;
      line-height: 18px;
      margin-top: 2px;
      border-top: 1px solid rgba($border-color, 0.6);
      padding-top: 20px;

      &:empty {
        display: none;
      }
    }
  }

  .qualitySectionWrapper {
    display: flex;
    padding: 30px;
  }

  .qualitySection {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 30px 0 20px;

    &:last-child {
      padding-right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $border-color;
      border-radius: 2px;
    }

    &[data-intent='success']::before {
      background-color: rgba($intent-success, 0.2);
    }

    &[data-intent='danger']::before {
      background-color: rgba($intent-danger, 0.2);
    }

    .info {
      flex: none;
      margin-bottom: 25px;

      &.good {
        color: $green2;
      }

      &.bad {
        color: $red1;
      }

      .label {
        font-weight: 700;
        margin-bottom: 14px;
        text-transform: uppercase;

        .dollarAmt {
          margin-left: 5px;
        }
      }

      .value {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        color: $black;

        .num {
          font-size: 42px;
          font-weight: 800;
          white-space: nowrap;
        }

        .perc {
          margin-left: 3px;
          font-size: $font-size-extra-large;
          font-weight: 800;
          align-self: flex-end;
          padding-bottom: 4px;
        }

        .descriptor {
          font-size: $font-size;
          font-weight: 600;
          margin-left: 7px;
          align-self: flex-end;
          padding-bottom: 6px;
        }

        .viewAll {
          margin-left: auto;
        }
      }

      .misc {
        font-size: $font-size;
        color: $font-color-muted;

        span {
          opacity: 0.4;
        }
      }
    }

    .noReasons {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 0 0 1px $border-color;
      border-radius: $border-radius;
      color: $font-color-muted;
      min-height: 60px;
      line-height: 19px;
    }

    .items {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          margin-bottom: 9px;
          white-space: nowrap;

          span {
            text-decoration: underline;
          }

          &:last-child {
            flex: 0 0 57px;
            font-size: 10px;
            color: $font-color-muted;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        padding: 5px 0;

        .text {
          font-size: $font-size;
          @include text-overflow-ellipsis();
          margin-right: auto;

          > span {
            margin-right: 7px;
          }
        }

        .stats {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
          margin-left: 5px;

          .count {
            font-size: $font-size;
            color: $font-color-muted;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .complianceSectionWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    .agendaStats {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding-bottom: 10px;

      .numbers {
        display: flex;
        align-items: center;
      }

      .area {
        display: flex;
        flex: 1 1 auto;
        position: relative;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
          justify-content: flex-end;
        }

        .label {
          font-size: $font-size;
          margin-right: 8px;
        }

        .percent {
          flex: 0 0 auto;
          font-size: $font-size;
          color: $font-color-muted;
        }
      }

      .bars {
        display: flex;
        position: relative;
        height: 4px;
        width: 100%;
        margin-top: 8px;

        > div {
          height: 100%;
          border-radius: 4px;
        }

        .has {
          flex: 1 1 auto;
          background: $green1;
          margin-right: 4px;
          min-width: 2%;
        }

        .doesNot {
          flex: 1 1 auto;
          background: $red1;
          min-width: 2%;
        }

        .zero {
          background: $gray2;
        }
      }
    }
  }

  @mixin small-width {
    padding-top: 30px;

    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px !important;
    }

    .card > span {
      width: 100%;
    }

    .moduleOverall {
      padding: 0;
      width: 100%;
      max-width: none;

      .title {
        padding: 18px 20px;
      }

      .content {
        margin-top: 0;
        padding: 0 20px 15px;
      }
    }

    .viewMoreStats {
      flex: none;
      text-transform: none;
      font-weight: 400;

      a {
        color: $font-color-muted;
        text-decoration: underline;
      }
    }
  }

  &.isColumnLayout {
    @include small-width();
  }

  @media (max-width: $breakpoint-mobile) {
    &:not(.isForMarketing) {
      @include small-width();

      .card {
        flex-direction: column;
      }

      .qualitySectionWrapper {
        flex-direction: column;
        padding: 20px;

        .qualitySection {
          padding-right: 0;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
