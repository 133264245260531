@import '../variables.scss';

.enter {
  opacity: 0;

  > div:first-child {
    opacity: 0;
  }
}

.enterActive {
  opacity: 1;

  > div:first-child {
    opacity: 1;
  }
}

.enterDone {
  opacity: 1;

  > div:first-child {
    opacity: 1;
  }
}

.exit {
  opacity: 1;

  > div:first-child {
    opacity: 1;
  }
}

.exitActive {
  opacity: 0;

  > div:first-child {
    opacity: 0;
  }
}

.exitDone {
  opacity: 0;

  > div:first-child {
    opacity: 0;
  }
}
