@import './variables.scss';

.notificationToast {
  @include elevation-3();
  display: flex;
  align-items: center;
  border-radius: $border-radius;

  :global(.react-toast-notifications__toast__icon-wrapper) {
    display: none;
  }

  :global(.react-toast-notifications__toast__content) {
    display: flex;
    padding: 0 15px;
  }

  .icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    border-right: 1px solid $border-color;
    padding-right: 15px;
    margin-right: 15px;
  }

  .text {
    line-height: 18px;
    padding: 12px 0 10px;
  }
}
