@import './variables.scss';

.organizerStats {
  display: flex;
  min-width: 0;

  &.isDownloading {
    width: 570px;
    flex-direction: column;
    padding: 0;
    background: $white;

    h5,
    div[data-label='description'] {
      display: none;
    }

    > div:first-child {
      padding-top: 0;
    }

    > div:last-child {
      padding-top: 20px;
    }

    > div {
      margin: 0;
      width: 100% !important;
      max-width: none;
    }

    div[data-label='roi-chart'] {
      height: 300px !important;
      box-shadow: none !important;
      border: 1px solid $gray2;

      > span {
        display: none;
      }
    }

    div[data-label='leaderboard'] {
      max-height: none !important;
      box-shadow: none !important;
      border: 1px solid $gray2;
    }

    div {
      overflow: visible !important;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    &:not(.isForMarketing) {
      flex-direction: column;
    }
  }
}
