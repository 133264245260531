@import './variables.scss';
@import './review.module.scss';

.miscStats {
  @include review-styles-section();

  .inner {
    display: flex;

    > .miscMetric {
      flex: 1 1 0;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      .noWellnessBlocksCallout {
        margin-top: 6px;
      }

      .link {
        color: $blue1;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .inner {
      flex-direction: column;

      > .miscMetric {
        margin-bottom: 30px;
      }
    }
  }
}
