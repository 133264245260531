@import '../variables.scss';

.organizerUpdate {
  .calBkg {
    background-color: rgba($gray2, 0.2);
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    position: absolute;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: repeating-linear-gradient(
        0deg,
        transparent 0px,
        transparent 30px,
        rgb(239, 241, 244) 30px,
        rgb(239, 241, 244) 31px
      );
    }

    &::after {
      background: repeating-linear-gradient(
        90deg,
        transparent 0px,
        transparent 60px,
        rgb(239, 241, 244) 60px,
        rgb(239, 241, 244) 61px
      );
    }

    .meetingInvites {
      position: absolute;
      top: 53px;
      left: 2px;

      .invit {
        height: 88px;
        border-radius: 1px;
        background-color: rgba($gray1, 0.2);
        margin-bottom: 5px;
        width: 60px;

        &:nth-child(2) {
          height: 57px;
        }

        &:nth-child(3) {
          height: 26px;
        }

        &:nth-child(4) {
          height: 57px;
        }
      }
    }
  }

  .editingMeeting {
    position: relative;
    top: 30px;
    left: 40px;
    height: 100%;
    width: 100%;
  }

  .inviteInner {
    padding: 20px;
  }

  .typing {
    position: absolute;
    top: 12px;
    left: 14px;
  }

  .ghost {
    opacity: 0;
  }

  .inputGroup {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: block;
      margin-bottom: 10px;
      color: $font-color-muted;
    }

    .input {
      min-height: 30px;
      border-radius: $border-radius;
      overflow: hidden;
      background-color: rgba($gray2, 0.2);
      box-shadow: inset 0 0 0 1px rgba($gray1, 0.5),
        inset 0 2px 6px rgba($gray1, 0.05);
      padding: 12px 14px 10px;
      position: relative;

      * {
        font-size: $font-size;
        color: $font-color;
        line-height: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      p,
      ul {
        margin: 0 0 10px;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .organizerUpdate {
    transform: scale(0.8);
    margin-left: -33px;
    margin-top: -50px;
    margin-bottom: -20px;
  }
}
