@import './variables.scss';

.calendarListLoading {
  display: flex;
  align-items: center;
  height: 30px;
  color: $font-color-muted;
}

.calendarList {
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 !important;

      &:last-child {
        margin-bottom: 0;
      }

      .info {
        display: flex;
        align-items: center;
        margin-right: auto;
        min-width: 0;
      }

      .name {
        @include text-overflow-ellipsis();
        flex: 1 1 auto;
        margin-right: 10px;
      }
    }
  }
}
