@import '../variables.scss';

.autoDecline {
  height: 350px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid rgba($gray1, 0.3);
  padding: 20px 80px 0;
  pointer-events: none;
  position: relative;

  .remind {
    display: block;

    > div {
      width: auto;
    }
  }

  .decline {
    position: absolute;
    opacity: 0;
    top: 20px;
    left: 80px;
    right: 80px;
    transition: opacity $anim-duration * 4 $anim-easing;

    > div {
      width: auto;
    }
  }

  &.isVisible {
    .decline {
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .autoDecline {
    padding: 20px 30px 0;

    .decline {
      left: 30px;
      right: 30px;
    }
  }
}
