@import '../variables.scss';

.heroArea {
  padding-top: 80px;
  margin-bottom: 70px;

  h1 {
    max-width: 600px;
    padding: 0 20px;
  }

  .desc {
    color: $font-color-muted;
    font-size: $font-size-extra-large;
    padding: 0 30px;
    max-width: 560px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 32px;

    @media (max-width: $breakpoint-mobile) {
      font-size: $font-size-large;
      line-height: 26px;
    }
  }

  .supercharge {
    display: flex;
    align-items: center;
    margin: 25px 0 0;
    color: $font-color-muted;
    font-size: $font-size-large;
    text-align: center;
    line-height: 32px;

    @media (max-width: $breakpoint-mobile) {
      font-size: $font-size-large;
      line-height: 26px;
    }

    span {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 20px !important;
      height: 20px !important;
      margin: 0 !important;

      > span {
        padding-left: 5px;
      }
    }

    label {
      margin: 0 3px 0 5px;
    }
  }

  .worksWith {
    display: flex;
    align-items: center;
    color: $font-color-muted;
    padding: 11px 0 20px;

    label {
      margin-right: 12px;
      line-height: 19px;
      height: 16px;
    }

    span {
      line-height: 17px;
      height: 16px;
      margin: 0 12px;
      font-size: 16px;
      opacity: 0.5;
    }

    .option {
      &.google {
        @include google-sign-in-button();
      }

      &.microsoft {
        @include outlook-sign-in-button();
      }

      .text {
        color: $font-color-muted;
      }
    }
  }
}
