@import './variables.scss';

html {
  font-size: $font-size;
  line-height: 1.15;
  overflow: hidden;
}

$header-height: 70px;
$footer-height: 130px;

body {
  margin: 0;
  padding: 0;
  font-family: 'Catamaran', sans-serif;
  font-weight: 400; // ONLY 400 (reg), 600 (medium), 800 (bold) for font-weight //
  color: $primary-color;
  background-color: $white;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}

.wrapper {
  @include bkg-gradient();
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  &.in-app {
    min-width: 850px;
    overflow-y: hidden;

    .main {
      flex-shrink: 1;
      min-height: auto;
    }
  }
}

.main {
  flex: 1 0 auto;
  display: flex;
  height: auto;
  overflow: hidden;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
}

h1 {
  font-size: 26px;
  margin: 0;
  font-weight: 400;

  span {
    font-weight: 700;
  }
}

h5 {
  font-size: $font-size-small;
  text-transform: uppercase;
  color: $font-color-muted;
  margin: 0;
  font-weight: 700;
}

h6 {
  font-size: $font-size-small;
  text-transform: uppercase;
  margin: 0 0 10px;
}

p {
  margin: 0 0 10px 0;
}

a {
  text-decoration: none;
  color: $blue1;
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: $border-color;
}

.placeholder-view {
  @include white-card-bkg();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 800px;

  > h1 {
    opacity: 0.3;
    text-align: center;
    padding: 0 30px;
    margin: 20px 0 20px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .wrapper.is-mobile {
    min-width: 0 !important;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.in-app {
      flex-direction: column-reverse;
      overflow: hidden !important;

      .main {
        height: calc(100% - #{$header-height});
        border-radius: 0 0 20px 20px;
      }
    }
  }
}
