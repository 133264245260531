@import '../variables.scss';

.reportCardStatsPreview {
  > div {
    @media (max-width: $breakpoint-mobile) {
      width: 770px;
      transform: scale(0.5);
      margin: -120px 0 -130px -190px;
    }
  }
}
