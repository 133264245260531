@import './variables.scss';

.faqView {
  @include running-text();
  display: flex;
  flex-direction: column;
  width: 100%;

  .inner {
    h1 {
      font-size: 48px !important;
      line-height: 56px !important;
      text-align: center;
      margin-bottom: 30px !important;
    }

    h3 {
      color: $secondary-color;
      text-align: center;
    }

    .divider {
      height: 1px;
      width: 100%;
      max-width: 300px;
      background-color: $border-color;
      margin: 0 auto;
    }

    .qAndA {
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      .section {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        .header {
          padding: 0 25px;
          font-family: 'Vollkorn', sans-serif;
          font-weight: 600;
          font-size: 38px;
          margin-bottom: 15px;
        }

        .items {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .item {
        flex: 1 1 auto;
        padding: 25px;
        max-width: 50%;
        position: relative;

        &:hover {
          .answer div {
            opacity: 1;
          }
        }

        .anchorLink {
          position: absolute;
          top: -90px;
          left: 0;
          right: 0;
          height: 1px;
          background: transparent;
          pointer-events: none;
        }

        .question {
          color: $black;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          margin-bottom: 15px;
        }

        .answer {
          color: rgba($black, 0.8);
          font-size: $font-size-medium;
          line-height: 22px;

          p {
            display: inline;
          }

          span {
            font-weight: 600;
          }

          div {
            display: inline;
            flex: none;
            opacity: 0;
            transition: opacity $anim-duration $anim-easing;
          }
        }

        ul {
          margin: 10px 0 0 0;
        }

        h4 {
          font-size: $font-size-small;
        }
      }

      @media (max-width: $breakpoint-small) {
        flex-direction: column;
        flex-wrap: nowrap;

        .item {
          max-width: 100%;
        }
      }
    }
  }
}
